import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  main {
    max-width: 800px;

    header {
      h1 {
        p {
          display: block;
          font-size: 1rem;
          font-weight: normal;
          margin: 0;
        }
        a {
          text-decoration: underline;
          font-size: 1.2rem;
          color: #000;
        }
      }
    }
    form {
      > section {
        padding: 2rem 0 1rem 0;

        > section {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: end;
        }
      }
    }

    hr {
      width: 100%;
      margin: 16px 0;
    }
  }

  @media screen and (max-width: 800px) {
    main {
      padding: 0 16px;
    }
  }
`;
