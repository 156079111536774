import Input from "components/Input";
import Select from "components/Select";
import React from "react";

export const patientSectionInitialValues = {
  patientName: "",
  patientMotherName: "",
  patientCNS: "",
  patientDob: "",
  patientSex: "",
  patientPhone: "",
  patientAddressCep: "",
  patientAddressStreet: "",
  patientAddressNumber: "",
  patientAddressComplement: "",
  patientAddressNeighborhood: "",
  patientAddressCity: "",
  patientAddressUf: "",
  patientAddressCountry: "Brasil",
};

export const patientSectionDefaultRequiredFields = {
  name: true,
  motherName: false,
  cns: false,
  dob: false,
  sex: false,
  phone: false,
  address: {
    cep: false,
    street: false,
    number: false,
    complement: false,
    neighborhood: false,
    city: false,
    uf: false,
    country: false,
  },
};

export const patientSectionDefaultInitialVisibleFields = {
  name: true,
  motherName: true,
  cns: true,
  dob: true,
  sex: true,
  phone: false,
  address: {
    cep: false,
    street: false,
    number: false,
    complement: false,
    neighborhood: false,
    city: false,
    uf: false,
    country: false,
  },
};

export default ({ formik, requiredFields, visibleFields }) => {
  const _requiredFields = {
      ...patientSectionDefaultRequiredFields,
      ...requiredFields,
    },
    _visibleFields = {
      ...patientSectionDefaultRequiredFields,
      ...patientSectionDefaultInitialVisibleFields,
      ...visibleFields,
    };

  return (
    <section>
      {_visibleFields.name ? (
        <Input
          name="patientName"
          placeholder="Nome Completo"
          formik={formik}
          required={_requiredFields.name}
        />
      ) : null}
      {_visibleFields.cns && (
        <Input
          name="patientCNS"
          placeholder="Cartão Nacional do SUS"
          formik={formik}
          required={_requiredFields.cns}
        />
      )}
      {_visibleFields.dob && (
        <Input
          type="date"
          name="patientDob"
          placeholder="Data de Nascimento"
          formik={formik}
          required={_requiredFields.dob}
        />
      )}
      {_visibleFields.sex && (
        <Select
          formik={formik}
          placeholder="Sexo"
          name="patientSex"
          options={[
            { label: "Masculino", value: "MALE" },
            { label: "Feminino", value: "FEMA" },
          ]}
          required={_requiredFields.sex}
        />
      )}
      {_visibleFields.motherName && (
        <Input
          name="patientMotherName"
          placeholder="Nome da Mãe"
          formik={formik}
          required={_requiredFields.motherName}
        />
      )}
      <hr />
      {_visibleFields.address.cep && (
        <Input
          name="patientAddressCep"
          placeholder="CEP"
          onBlur={async (e) => {
            const rtn = await fetch(
                `https://viacep.com.br/ws/${e.target.value}/json/`
              ),
              {
                complemento: patientAddressComplement,
                localidade: patientAddressCity,
                uf: patientAddressUf,
                logradouro: patientAddressStreet,
                bairro: patientAddressNeighborhood,
              } = await rtn.json();
            formik.setValues({
              ...formik.values,
              patientAddressComplement,
              patientAddressCity,
              patientAddressUf,
              patientAddressStreet,
              patientAddressNeighborhood,
            });
            formik.setTouched(
              {
                ...formik.touched,
                complement: true,
                city: true,
                uf: true,
                street: true,
                neighborhood: true,
              },
              true
            );
          }}
          formik={formik}
          required={_requiredFields.address.cep}
        />
      )}
      {_visibleFields.address.street && (
        <Input
          name="patientAddressStreet"
          label="Rua"
          formik={formik}
          required={_requiredFields.address.street}
        />
      )}
      {_visibleFields.address.number && (
        <Input
          name="patientAddressNumber"
          placeholder="Número"
          formik={formik}
          required={_requiredFields.address.number}
        />
      )}
      {_visibleFields.address.complement && (
        <Input
          name="patientAddressComplement"
          placeholder="Complemento"
          formik={formik}
          required={_requiredFields.address.complement}
        />
      )}
      {_visibleFields.address.neighborhood && (
        <Input
          name="patientAddressNeighborhood"
          placeholder="Bairro"
          formik={formik}
          required={_requiredFields.address.neighborhood}
        />
      )}
      {_visibleFields.address.city && (
        <Input
          name="patientAddressCity"
          placeholder="Cidade"
          formik={formik}
          required={_requiredFields.address.city}
        />
      )}
      {_visibleFields.address.uf && (
        <Input
          name="patientAddressUf"
          placeholder="UF"
          formik={formik}
          required={_requiredFields.address.uf}
        />
      )}
      {_visibleFields.address.country && (
        <Input
          name="patientAddressCountry"
          placeholder="País"
          defaultValue="Brasil"
          formik={formik}
          required={_requiredFields.address.country}
        />
      )}
      {_visibleFields.phone && (
        <Input
          name="patientPhone"
          placeholder="Telefone"
          formik={formik}
          required={_requiredFields.phone}
        />
      )}
    </section>
  );
};
