import Main, { StyledCheckItem } from "./styles";

import PageTemplate from "components/LYT_SimplePage";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useParallax, ParallaxProvider } from "react-scroll-parallax";
import useWindowDimensions from "services/hooks/useWindowDimensions";

const CheckItem = ({ title, description }) => (
  <StyledCheckItem>
    <h4>
      <span role="img" aria-label="check emoji">
        ✅
      </span>{" "}
      {title}
    </h4>
    <p>{description}</p>
  </StyledCheckItem>
);

const Page = () => {
  const { width: vw } = useWindowDimensions(),
    target = useRef(null),
    initialParallax1Props = {
      translateX: [0, 20],
      rotateY: [0, 30],
      scale: [1, 0.9],
      easing: "easeInOutCubic",
      targetElement: target.current,
    },
    initialParallax3Props = {
      translateX: [0, -20],
      rotateY: [0, -30],
      scale: [1, 0.9],
      easing: "easeInOutCubic",
      targetElement: target.current,
    },
    [parallax1Props, setParallax1Props] = useState(initialParallax1Props),
    [parallax3Props, setParallax3Props] = useState(initialParallax3Props),
    parallax1 = useParallax(parallax1Props),
    parallax2 = useParallax({
      speed: 0.2,
      scale: [1, 1.2],
      targetElement: target.current,
    }),
    parallax3 = useParallax(parallax3Props);

  useEffect(() => {
    const resetParallaxProps = () => {
      setParallax1Props(initialParallax1Props);
      setParallax3Props(initialParallax3Props);
    };
    if (vw <= 415) {
      setParallax1Props({
        translateY: [0, 10],
        rotateX: [0, -30],
        scale: [1, 0.9],
        easing: "easeInOutCubic",
        targetElement: target.current,
      });
      setParallax3Props({
        translateY: [0, -10],
        rotateX: [0, 30],
        scale: [1, 0.9],
        easing: "easeInOutCubic",
        targetElement: target.current,
      });
    } else {
      resetParallaxProps();
    }
    return resetParallaxProps;
  }, [initialParallax1Props, initialParallax3Props, vw]);

  return (
    <PageTemplate noAds>
      <Main>
        <div className="center">
          <h1>Mais Recursos</h1>
          <p>
            Criando uma conta você implementa mais facildades ainda à sua rotina
            e ganha tempo com o paciente, sem a parte chata e burocrática da
            rotina de documentos. Deixa isso com a gente!
          </p>
        </div>
        <section id="planos" name="planos">
          <h1>Faça a sua conta</h1>
          <div>
            <div ref={parallax1.ref}>
              <h2>
                <span role="img" aria-label="emoji com sorriso aberto">
                  😄
                </span>{" "}
                Com uma conta!
              </h2>
              <ul>
                <CheckItem
                  title="Uso dos recursos gratuitos"
                  description="Prescrição de APAC e Autorização para Mamografia, solicitação limitada de exames e prescrição SUS"
                />
                <CheckItem title="Preenchimento automático de dados do profissional" />
                <CheckItem
                  title="Salvar Preferências de Instituição"
                  description="Salve a instituição que você mais usa"
                />
              </ul>
              <button onClick={() => window.open("/cadastro")}>
                Criar uma conta
              </button>
            </div>
            <div ref={parallax2.ref}>
              <h2>
                <span role="img" aria-label="emoji festejando">
                  🥳
                </span>{" "}
                Seja Premium
              </h2>
              <ul>
                <CheckItem title="Todos os benefícios de ter uma conta" />
                <CheckItem title="Crie seus próprios grupos de exames" />
                <CheckItem title="Crie e reutilize suas prescrições padrão" />
                <CheckItem
                  title="Faça integração com outros produtos"
                  description="Integração com Plugin PEC eSUS e Programa Meus Pacientes"
                />
                <CheckItem
                  title="Garanta os benefícios que estão por vir"
                  description="Cartão da gestante online, calculadora de dose infantil, biblioteca de prescrições fitoterápicas e mais!"
                />
              </ul>
              <button
                className="premium-style"
                onClick={() => window.open("/cadastro?plan=premium")}
              >
                Quero meu Premium!
              </button>
            </div>
            <div ref={parallax3.ref}>
              <h2>
                <span role="img" aria-label="emoji com sorriso">
                  🙂
                </span>{" "}
                Sem uma Conta
              </h2>
              <ul>
                <CheckItem
                  title="Uso dos recursos gratuitos"
                  description="Prescrição de APAC e Autorização para Mamografia, solicitação limitada de exames e prescrição SUS"
                />
                <CheckItem
                  title="Salve seus dados do profissional no navegador"
                  description="Esses dados desaparecerão se outra pessoa utilizar a mesma página e trocar o profissional em uso"
                />
              </ul>
            </div>
          </div>
        </section>
        <section className="center">
          <h1>O que vem à Seguir?</h1>
          <ul>
            <li>
              Cadastros de prescrição e LME de Repetição{" "}
              <p>
                Muitas vezes precisamos imprimir um mesmo documento com certa
                periodicidades, porque não armazená-las e apenas atualizar
                automaticamente quando for preciso reimprimir?
              </p>
            </li>
            <li>
              Copiar prescrição e condutas realizadas no Docs para prontuário
              facilmente
            </li>
            <li>
              Novos documentos públicos, como orientações nutricionais e
              comportamentais em doenças crônicas
            </li>
            <li>Caderneta da gestante online</li>
            <li>
              Prescrição/Recomendações de Aplicativos e Links Relevantes para o
              Paciente
            </li>
            <li>
              Biblioteca pública de grupo de exames e medicações aumentadas,
              inclusive fitoterápicos
            </li>
            <li>Prescrição infantil com calculo de dose</li>
            <li>
              Solicitação de Templates próprios
              <p>
                Nossos usuários premium poderão solicitar seus próprios
                templates de prescrição e de Solicitação de Exames. Nossa equipe
                de designer estará pronto para atender a demanda em até 30 dias,
                sob cobrança adicional a ser negociada
              </p>
            </li>
          </ul>
          <h1>Aproveite essa chance!</h1>
          <p>Os 70 primeiros a utilizar esse cupom terão um </p>
          <strong>
            Desconto de<strong>70%</strong>
          </strong>
          <button
            className="cupom-badge"
            onClick={() => window.open("/cadastro", "_blank")}
          >
            Utilize o cupom EARLY70 na hora da compra
          </button>
          <button
            className="premium-style"
            onClick={() => window.open("/cadastro?plan=premium", "_blank")}
          >
            Quero meu Premium!
          </button>
        </section>
      </Main>
    </PageTemplate>
  );
};
export default () => {
  return (
    <ParallaxProvider>
      <Page />
    </ParallaxProvider>
  );
};
