import { useSearchParams } from "react-router-dom";

function usePatientParams(initialValues) {
  const [searchParams, _] = useSearchParams();

  return {
    ...initialValues,
    patientName: searchParams.get("patientName"),
    patientCNS: searchParams.get("patientCNS"),
    patientMotherName: searchParams.get("patientMotherName"),
    patientDob: searchParams.get("patientDob"),
    patientSex: searchParams.get("patientSex"),
  };
}

export default usePatientParams;

// http://localhost:81/mamografia?patientName=Filipe%20Lopes&patientCNS=753683359910001&patientMotherName=Adriana%20Lopes&patientDob=1995-12-01&patientSex=MALE
