import styled from "styled-components";

export default styled.div`
  background: ${({ theme }) => theme.bluePurpleGradient};
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #0000c1;
  position: relative;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  width: 100%;
  margin: 1rem 0;
  color: white;

  a {
    text-decoration: none;
    color: white;
  }

  header {
    border: 1px solid white;
    padding: 4px 16px 4px 8px;
    display: inline-block;
    border-radius: 4px;
    line-height: 22px;
    margin-right: 8px;
  }

  button {
    border: 1px solid white;
    padding: 4px 8px;
    border-radius: 4px;
    background: transparent;
    color: white;
    position: absolute;
    font-size: 1.2rem;
    right: 8px;
    bottom: 8px;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
    transform: scale(1.02);

    header {
      background-color: white;
      color: blue;
    }

    button {
      background-color: white;
      color: blue;
    }
  }
`;
