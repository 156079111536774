import { GENERATE_PDF_MAMOGRAFIA } from "services/api";
import AppContext from "services/context";
import { b64toBlob } from "services/utils";

import validationSchema from "./validationSchema";

import patientSectionValidationSchema from "components/PatientFormSection/validationSchema";

import { useMutation } from "@apollo/client";
import Button from "components/Button";
import EstablishmentFormSection from "components/EstablishmentFormSection";
import Input from "components/Input";
import FormPageTemplate from "components/LYT_FormPage";
import PatientFormSection, {
  patientSectionInitialValues,
} from "components/PatientFormSection";
import ProfessionalFormSection from "components/ProfessionalFormSection";
import Select from "components/Select";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import usePatientParams from "services/hooks/usePatientParams";

const initialValues = {
    establishmentName: "USF Sede II - Água Fria",
    establishmentCNES: "5210143",
    professionalUf: "BA",
    anamnesisHasNodule: "NAO",
    anamnesisBreastSurgery: ["NAO"],
    anamnesisHasHighRisk: "NAO",
    anamnesisEvaluatedBreast: "SIM",
    anamnesisBreastRadiotherapy: "NAO",
    anamnesisBreastTestBefore: "SIM",
    clinicalIndication: "screening",
    clinicalIndicationScreeningCriteria: "targetPopulation",
  },
  initialTouched = {
    anamnesisBreastTestBefore: true,
    clinicalIndication: true,
  },
  patientSectionRequiredFields = {
    name: true,
    motherName: true,
    dob: true,
    sex: true,
    cns: true,
    address: {},
  },
  patientSectionVisibleFields = {
    address: {
      cep: true,
      street: true,
      number: true,
      complement: true,
      neighborhood: true,
      city: true,
      uf: true,
      country: true,
    },
  },
  anamnesisHasNoduleOptions = [
    {
      value: "SIMDIR",
      label: "Sim, Mama Direita",
    },
    {
      value: "SIMESQ",
      label: "Sim, Mama Esquerda",
    },
    {
      value: "NAO",
      label: "Não",
    },
  ],
  anamnesisHasHighRiskOptions = [
    {
      value: "SIM",
      label: "Sim",
    },
    {
      value: "NAO",
      label: "Não",
    },
    {
      value: "NAOSABE",
      label: "Não sabe",
    },
  ],
  anamnesisEvaluatedBreastOptions = [
    {
      value: "SIM",
      label: "Sim",
    },
    {
      value: "NAO",
      label: "Nunca foram examinadas anteriormente",
    },
    {
      value: "NAOSABE",
      label: "Não sabe",
    },
  ],
  anamnesisBreastTestBeforeOptions = [
    {
      value: "SIM",
      label: "Sim",
    },
    {
      value: "NAO",
      label: "Não",
    },
    {
      value: "NAOSABE",
      label: "Não sabe",
    },
  ],
  anamnesisBreastRadiotherapyOptions = [
    {
      value: "SIMDIR",
      label: "Sim, mama direita",
    },
    {
      value: "SIMESQ",
      label: "Sim, mama esquerda",
    },
    {
      value: "NAO",
      label: "Não",
    },
    {
      value: "NAOSABE",
      label: "Não sabe",
    },
  ],
  breastSugeryTypes = [
    { value: "biopsiaIncisional", label: "Biópsia cirúrgica incisional" },
    { value: "biopsiaExcisional", label: "Biópsia cirúrgica excisional" },
    { value: "centraledomia", label: "Centralectomia" },
    { value: "segmentectomia", label: "Segmentectomia" },
    { value: "dutectomia", label: "Dutectomia" },
    { value: "mastectomia", label: "Mastectomia" },
    {
      value: "mastectomiaPoupadoraPele",
      label: "Mastectomia poupadora de pele",
    },
    {
      value: "mastectomiaPoupadoraPeleComplexoAreolo",
      label: "Mastectomia poupadora de pele e complexo aréolo-papilar",
    },
    { value: "linfadenectomiaAxilar", label: "Linfadenectomia axilar" },
    {
      value: "biopsiaLinfonodoSentinela",
      label: "Biópsia de linfonodo sentinela",
    },
    { value: "reconstrucaoMamaria", label: "Reconstrução mamária" },
    { value: "mastoplastiaRedutora", label: "Mastoplastia redutora" },
    { value: "indusaoImplantes", label: "Inclusão de implantes" },
  ],
  anamnesisBreastSurgeryOptions = [
    {
      value: "NAO",
      label: "Não fez cirurgia",
    },
    ...[
      { value: "rightBreast", label: "Mama direita" },
      { value: "leftBreast", label: "Mama esquerda" },
    ].reduce((acc, breastSide) => {
      return [
        ...breastSugeryTypes.map((sugeryType) => ({
          value: `${breastSide.value}_${sugeryType.value}`,
          label: `${sugeryType.label} - ${breastSide.label}`,
        })),
        ...acc,
      ];
    }, []),
  ],
  clinicalIndicationOptions = [
    { value: "diagnostic", label: "Mamografia diagnóstica" },
    { value: "screening", label: "Mamografia de Rastramento" },
  ],
  clinicalIndicationScreeningCriteriaOptions = [
    { value: "targetPopulation", gqlValue: "POPALVO", label: "População alvo" },
    {
      value: "highRiskPopulation",
      gqlValue: "RISCOELEVADO",
      label: "População de risco elevado (história familiar)",
    },
    {
      value: "treatedPopulation",
      gqlValue: "JATRATADO",
      label: "Paciente já tratado de câncer de mama",
    },
  ],
  clinicalIndicationClinicalExaminationCriteriaOptions = [
    { value: "physicalExaminationFindings", label: "Achados no Exame Clínico" },
    { value: "birads3", label: "Controle radiológico categoria 3" },
    { value: "cancerDiagnosis", label: "Lesão com diagnóstico de cancer" },
    {
      value: "treatmentResponseEvaluation",
      label: "Avaliação da resposta de QT",
    },
    {
      value: "previousMamogramWithFindings",
      label: "Revisão de mamografia com lesão, realizada em outra instituição",
    },
    {
      value: "PAAF",
      label:
        "Controle de lesão após biópsia defragment ou PAAF com resultado benigno",
    },
  ];
export default () => {
  const [generatePdfMamografia, { loading, error, data }] = useMutation(
      GENERATE_PDF_MAMOGRAFIA
    ),
    formik = useFormik({
      initialValues: usePatientParams({
        ...patientSectionInitialValues,
        ...initialValues,
      }),
      initialTouched,
      validationSchema: validationSchema.concat(
        patientSectionValidationSchema(patientSectionRequiredFields)
      ),
      onSubmit: (values) => {
        setLoading(true);
        const variables = {
          ...values,
          anamnesisBreastSurgery_didNot: values.anamnesisBreastSurgery.includes(
            "NAO"
          )
            ? true
            : false,
          clinicalIndicationScreeningCriteria:
            clinicalIndicationScreeningCriteriaOptions.filter(
              (e) => e.value === values.clinicalIndicationScreeningCriteria
            )[0]?.gqlValue,
          clinicalIndicationClinicalExaminationCriteria: null,
        };
        console.log(variables);
        generatePdfMamografia({ variables });
      },
    }),
    { setLoading, setShowLGPDTerms, acceptedLGPDTerms } =
      useContext(AppContext);

  useEffect(() => {
    console.log(loading, error, data);

    if (data) {
      setLoading(false);
      const fileWindow = window.open(),
        file = b64toBlob(
          data.generatePdfMamografia.base64Pdf,
          "application/pdf"
        ),
        url = URL.createObjectURL(file);
      fileWindow.document.write(
        "<title>Visualization</title>" +
          '<body style="overflow: hidden; margin: 0">' +
          '<object width="100%" width="-webkit-fill-available" height="100%" height="-webkit-fill-available" type="application/pdf" data="' +
          encodeURI(url) +
          '"></object>' +
          "</body>"
      );
    }

    if (error && !loading) {
      setLoading(false);
    }
  }, [loading, error, data, setLoading]);

  useEffect(() => {
    if (!acceptedLGPDTerms) setShowLGPDTerms(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    console.log(anamnesisBreastSurgeryOptions);
  }, []);

  return (
    <FormPageTemplate>
      <main>
        <header>
          <h1>
            <p>Gerar</p>Requisição de Mamografia{" "}
            <Link to="/">[Ver outros Documentos]</Link>
            <p>
              Programa Nacional de Controle do Câncer de Mama - Ministério da
              Saúde
            </p>
          </h1>
          <p className="warning">
            Esse documento, nessa versão, está incompleto e não contém a seção
            de Indicação Clínica - 7 - Mamografia Diagnóstica por ser
            considerado de reduzida aplicabilidade prática nos maiores casos de
            uso que são os de rastreio
          </p>
        </header>
        <form onSubmit={formik.handleSubmit}>
          <section>
            <header>
              <h2>Dados do Solicitante</h2>
            </header>
            <ProfessionalFormSection formik={formik} />
          </section>
          <section>
            <header>
              <h2>Dados do Estabelecimento</h2>
            </header>
            <EstablishmentFormSection formik={formik} />
          </section>
          <section>
            <header>
              <h2>Dados do Paciente</h2>
            </header>
            <PatientFormSection
              formik={formik}
              requiredFields={patientSectionRequiredFields}
              visibleFields={patientSectionVisibleFields}
            />
          </section>
          <section>
            <header>
              <h2>Dados da Anamnese</h2>
            </header>
            <section>
              <Select
                placeholder="Tem Nódulo ou caroço na mama?"
                name="anamnesisHasNodule"
                required={true}
                formik={formik}
                options={anamnesisHasNoduleOptions}
              />
              <Select
                label="Apresenta risco elevado para câncer de mama?"
                description="Histórico familiar (< 50a, CA bilateral), diagnóstico histopatológico, marcadores genéticos"
                name="anamnesisHasHighRisk"
                required={true}
                formik={formik}
                options={anamnesisHasHighRiskOptions}
              />
              <Select
                label="Antes dessa consulta, teve suas mamas examinadas por um profissional de Saúde?"
                name="anamnesisEvaluatedBreast"
                required={true}
                formik={formik}
                options={anamnesisEvaluatedBreastOptions}
              />
              <Select
                label="Fez mamografia alguma vez?"
                name="anamnesisBreastTestBefore"
                required={true}
                formik={formik}
                options={anamnesisBreastTestBeforeOptions}
              />
              {formik.values.anamnesisBreastTestBefore === "SIM" &&
                formik.touched.anamnesisBreastTestBefore && (
                  <Input
                    label="Ano de Realização da última mamografia"
                    placeholder="> 1899"
                    name="anamnesisLastBreastTestYear"
                    required={true}
                    formik={formik}
                  />
                )}
              <Select
                label="Fez radioterapia na mama ou no plastrão? Em que ano?"
                name="anamnesisBreastRadiotherapy"
                required={true}
                formik={formik}
                options={anamnesisBreastRadiotherapyOptions}
              />
              <Select
                label="Fez cirurgia de mama? Em que ano?"
                name="anamnesisBreastSurgery"
                required={true}
                formik={formik}
                options={anamnesisBreastSurgeryOptions}
                isMulti={true}
              />
              {!formik.values.anamnesisBreastSurgery.includes("NAO") &&
                formik.values.anamnesisBreastSurgery.map((breastSugery) => (
                  <Input
                    key={breastSugery}
                    label={`Ano de Realização da ${
                      anamnesisBreastSurgeryOptions.filter(
                        (o) => o.value === breastSugery
                      )[0].label
                    }`}
                    placeholder="> 1899"
                    name={`anamnesisBreastSurgery_${breastSugery}`}
                    required={true}
                    formik={formik}
                  />
                ))}
            </section>
          </section>
          <section>
            <header>
              <h2>Indicação Clínica</h2>
            </header>
            <section>
              <Select
                label="Qual a indicação Clínica?"
                name="clinicalIndication"
                required={true}
                formik={formik}
                options={clinicalIndicationOptions}
                onChange={(e) => {
                  if (e.value !== formik.values.clinicalIndication)
                    formik.setValues({
                      ...formik.values,
                      clinicalIndicationClinicalExaminationCriteria: null,
                      clinicalIndicationScreeningCriteria: null,
                    });
                }}
              />
              {formik.values.clinicalIndication === "diagnostic" && (
                <Select
                  label="Qual a indicação Clínica?"
                  name="clinicalIndicationClinicalExaminationCriteria"
                  required={true}
                  formik={formik}
                  options={clinicalIndicationClinicalExaminationCriteriaOptions}
                />
              )}
              {formik.values.clinicalIndication === "screening" && (
                <Select
                  label="Qual a indicação Clínica?"
                  name="clinicalIndicationScreeningCriteria"
                  required={true}
                  formik={formik}
                  options={clinicalIndicationScreeningCriteriaOptions}
                />
              )}
            </section>
          </section>
          {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}
          <Button type="submit">Enviar</Button>
        </form>
      </main>
    </FormPageTemplate>
  );
};
