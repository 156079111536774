import StyledAside from "./styles";

import AppContext from "services/context";

import Plan from "components/Plan";
import PremiumBadge from "components/PremiumBadge";
import Settings from "components/Settings";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

const Aside = ({ menuIsOpen, setMenuIsOpen, name }) => {
  const [width, setWidth] = useState(220),
    [pageRouter, setPageRouter] = useState(null),
    { user, professional, setProfessional, setToken } = useContext(AppContext);

  return (
    <StyledAside open={menuIsOpen} width={width}>
      {name && (
        <header>
          <button onClick={() => setMenuIsOpen(!setMenuIsOpen)}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="black"
              />
            </svg>
          </button>
          <h2>
            {pageRouter && (
              <button
                onClick={() => {
                  setPageRouter(null);
                  setWidth(220);
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
                    fill="black"
                  />
                </svg>
              </button>
            )}{" "}
            Olá {name}
          </h2>
        </header>
      )}
      {!user?.name && (
        <section>
          <p>
            Você não tem cadastro no site, mas pode continuar aproveitando os
            recursos gratuitos. Seus dados estão salvos nessa máquina para
            facilitar o preenchimento dos formulário
          </p>
          {professional?.name && (
            <button
              onClick={() => {
                localStorage.removeItem("docsfilipelopesmedbr:appv1");
                setProfessional({});
                setMenuIsOpen(false);
              }}
            >
              Esquecer-me nesse computador
            </button>
          )}
          <p>Aproveite mais benefícios! Seja</p>
          <PremiumBadge type="medium" />
        </section>
      )}

      {user?.name && (
        <section>
          {pageRouter === "plan" ? (
            <Plan />
          ) : pageRouter === "settings" ? (
            <Settings />
          ) : (
            <nav>
              <ul>
                {Boolean(user.isPremium && user.isActive) && (
                  <li>
                    <Link to="/meus-exames">
                      <button>Grupo de Exames</button>
                    </Link>
                  </li>
                )}
                {Boolean(user.isPremium && user.isActive) && (
                  <li>
                    <button
                      onClick={() => {
                        setPageRouter("settings");
                        setWidth(460);
                      }}
                    >
                      Configurações
                    </button>
                  </li>
                )}
                <li>
                  <button
                    onClick={() => {
                      setPageRouter("plan");
                      setWidth(500);
                    }}
                    style={{
                      backgroundColor:
                        Boolean(user.isPremium && !user.isActive) &&
                        "rgba(255,0,0, 0.8)",
                    }}
                  >
                    {Boolean(user.isPremium && !user.isActive) && (
                      <span>
                        <svg
                          width="14"
                          height="10"
                          viewBox="0 0 18 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16 16L18 18V19H0V18L2 16V10C2 6.64999 4.35999 3.85001 7.5 3.17V2C7.5 1.17 8.17001 0.5 9 0.5C9.82999 0.5 10.5 1.17 10.5 2V3.17C13.64 3.85001 16 6.64999 16 10V16ZM10.99 20.01C10.99 21.11 10.1 22 9 22C7.90002 22 7.01001 21.11 7.01001 20.01H10.99ZM8 15V13H10V15H8ZM8 7V11H10V7H8Z"
                            fill="red"
                          />
                        </svg>
                      </span>
                    )}{" "}
                    Assinatura
                  </button>
                </li>
                <li>
                  <button onClick={() => setToken(null)}>Sair</button>
                </li>
              </ul>
            </nav>
          )}
        </section>
      )}
      {user?.isPremium && <PremiumBadge type="small" />}
    </StyledAside>
  );
};

Aside.propTypes = {
  menuIsOpen: PropTypes.bool.isRequired,
  setMenuIsOpen: PropTypes.func.isRequired,
  name: PropTypes.string,
};

Aside.defaultProps = {
  name: "[Nome]",
};

export default Aside;
