import styled, { css } from "styled-components";

export default styled.div`
  width: 260px;
  height: 200px;
  text-decoration: none;
  position: relative;
  transition: all 0.5s;
  border-radius: 10px;
  border: 8px solid #ccc;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-bottom: 1.2rem;

  @media screen and (max-width: 600px) {
    width: 180px;
    height: 180px;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 5;

    .menuIcon_description {
      display: block;
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;

      &:hover {
        transform: scale(1);

        .menuIcon_description {
          display: none;
        }
      }
    `}

  .menuIcon_background {
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-position: top;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    border-radius: 8px;
  }

  .menuIcon_cover {
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
  }

  .menuIcon_title {
    z-index: 3;
    position: absolute;
    bottom: 10px;
    left: 0;
    padding: 8px;
  }

  .menuIcon_description {
    display: none;
    width: calc(100% + 16px);
    z-index: 3;
    position: absolute;
    top: 182px;
    left: -8px;
    margin: 0;
    background-color: #fff;
    padding: 8px;
    box-sizing: border-box;
    border: 8px solid #ccc;
    border-top: none;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);

    p {
      padding: 0;
      margin-top: 0;
    }
  }

  input,
  textarea {
    outline: none;
    border: none;
    border-radius: 5px;
    background: #f0f0f0;
    padding: 0.5rem 1rem;
    margin-bottom: 0.2rem;
    border: 1px solid blue;
    box-sizing: border-box;
    height: 2rem;

    &::placeholder {
      color: #ccc;
    }
  }

  p {
    font-size: 0.7rem;
  }

  span {
    color: red;
  }

  span.error {
    display: block;
    color: red;
    font-size: 0.8rem;
  }
`;
