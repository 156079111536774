import Footer from "./styles";

import AppContext from "services/context";

import { ReactComponent as FilipeLopesMedBrLogo } from "assets/logo-filipelopesmedbr.svg";
import { ReactComponent as OrangoLogo } from "assets/logo-orango.svg";

import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

export default ({ children, withBackground }) => {
  const [hover, setHover] = React.useState(false),
    { user } = useContext(AppContext);

  return (
    <Footer className={`${hover ? "hover" : ""} ${withBackground ? "bg" : ""}`}>
      {children}
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <a
          href="https://filipelopes.med.br/links"
          target="_blank"
          rel="noreferrer"
        >
          <FilipeLopesMedBrLogo id="filipelopesmedbr-logo" />
        </a>
        <a
          href="https://filipelopes.med.br/links"
          target="_blank"
          rel="noreferrer"
        >
          Docs • Filipe Lopes <br /> Tecnologia em Saúde
        </a>
      </div>
      <div>
        {user && (
          <div>
            <a
              href={`https://wa.me/5571986056232?text=${encodeURIComponent(
                `Olá, sou ${user?.name}, meu email de cadastro é ${user?.email}. \n\n Encontrei um erro de funcionamento e gostaria de notificar para contribuir com o desenvolvimento do sistema. Segue abaixo \n\n- a descrição do problema,\n- as imagens e/ou vídeos de evidência!\n\n_(Essa mensagem precisa ser enviada)_`
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="wpp">
                Encontre um erro de funcionamento e ganhe mensalidade grátis!*
              </button>
            </a>
            <small>
              <Link to="/reportar-erro" target="_blank" rel="noreferrer">
                * Verifique as regras da promoção
              </Link>
            </small>
          </div>
        )}
        <div>
          <a
            href="https://wa.me/5571992518950?text=Ol%C3%A1%2C+gostaria+de+falar+sobre+algo+que+est%C3%A1+ocorrendo+na+aplica%C3%A7%C3%A3o+Docs."
            target="_blank"
            rel="noreferrer"
          >
            <button className="wpp">
              Alguma dúvida, problema, crítica ou sugestão? Chama por aqui!{" "}
              <span>Estamos desenvolvendo com você!</span>
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.271423 14.1859L1.21348 10.7485C0.634244 9.74282 0.325471 8.6002 0.328696 7.43217C0.328696 3.77204 3.30772 0.796188 6.9679 0.796188C8.7438 0.796188 10.4115 1.4868 11.6624 2.7408C12.9163 3.99479 13.607 5.66255 13.6038 7.43534C13.6038 11.0955 10.6248 14.0713 6.96462 14.0713H6.9615C5.85288 14.0709 4.762 13.793 3.78831 13.2629L0.271423 14.1859V14.1859ZM3.95379 12.0598L4.1543 12.1808C5.00094 12.6836 5.97163 12.9478 6.9615 12.951H6.96467C10.0041 12.951 12.4803 10.478 12.4803 7.43534C12.4803 5.96176 11.9074 4.57725 10.8667 3.53333C9.82593 2.48941 8.43825 1.91651 6.96467 1.91651C3.92198 1.91651 1.44585 4.38948 1.44585 7.43217C1.44585 8.47292 1.73544 9.48821 2.28926 10.3666L2.41977 10.5767L1.86277 12.6104L3.95379 12.0598Z"
                  fill="white"
                />
                <path
                  d="M5.30646 4.65377C5.1823 4.37685 5.05179 4.37046 4.93408 4.36729C4.83861 4.36412 4.72723 4.36412 4.6158 4.36412C4.50442 4.36412 4.32615 4.40549 4.17341 4.57097C4.0206 4.73651 3.59412 5.13753 3.59412 5.95553C3.59412 6.77025 4.18931 7.55956 4.27205 7.67093C4.35479 7.78236 5.42101 9.51056 7.10466 10.1758C8.50507 10.7296 8.79154 10.6181 9.09387 10.5895C9.39942 10.5609 10.0742 10.1885 10.2142 9.8002C10.351 9.41192 10.351 9.0809 10.3097 9.01089C10.2683 8.94083 10.1569 8.89952 9.99138 8.81672C9.8259 8.73398 9.01113 8.33296 8.85838 8.27569C8.70558 8.21842 8.5942 8.19295 8.486 8.35843C8.37457 8.52391 8.05634 8.89629 7.96081 9.00773C7.86534 9.1191 7.76669 9.13184 7.60116 9.0491C7.43568 8.96636 6.90098 8.79126 6.26765 8.22476C5.77432 7.78553 5.44014 7.24128 5.34461 7.0758C5.24913 6.91032 5.3351 6.82113 5.41784 6.73844C5.49102 6.66521 5.58332 6.54427 5.66612 6.4488C5.74885 6.35332 5.77749 6.28332 5.83159 6.17189C5.8857 6.06051 5.86023 5.96504 5.81886 5.8823C5.77749 5.7995 5.45287 4.98156 5.30646 4.65377Z"
                  fill="white"
                />
              </svg>
            </button>
          </a>
        </div>
        <div className="flex">
          Com participação
          <a href="https://orango.io" target="_blank" rel="noreferrer">
            <OrangoLogo id="orango-logo" />
          </a>
          &nbsp;&copy; 2023 - 2024
        </div>
      </div>
    </Footer>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
  withBackground: PropTypes.bool,
};

Footer.defaultProps = {
  withBackground: true,
};
