import { Container } from "./styles";

import AdsTopBar from "components/AdsTopBar";
import Footer from "components/Footer";
import Header from "components/Header";
import PropTypes from "prop-types";
import React from "react";
import { MetaTags } from "react-meta-tags";

const PageTemplate = ({ children, metaTags }) => (
  <Container>
    {metaTags && (
      <MetaTags>
        {metaTags?.title && <title>{metaTags?.title}</title>}
        {metaTags?.description && (
          <meta name="description" content={metaTags?.description} />
        )}
        {metaTags?.title && (
          <meta property="og:title" content={metaTags?.title} />
        )}
        {metaTags?.imagePath && (
          <meta property="og:image" content={metaTags?.imagePath} />
        )}
        {metaTags?.description && (
          <meta property="og:description" content={metaTags?.description} />
        )}
        {metaTags?.keywords && (
          <meta name="keywords" content={metaTags?.keywords} />
        )}
      </MetaTags>
    )}
    <AdsTopBar />
    <Header />
    {children}
    <Footer />
  </Container>
);

PageTemplate.propTypes = {
  children: PropTypes.elementType.isRequired,
  metaTags: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    imagePath: PropTypes.string,
  }),
};

export default PageTemplate;
