import React from "react";

export default () => (
  <article>
    <h1>Política de Privacidade</h1>

    <section>
      <h2>Introdução</h2>

      <p>
        Bem-vindo à Política de Privacidade do Docs - Filipe Lopes, pertencente
        à empresa FILIPELOPES MED BR LTDA, nome fantasia ORANGO I/O TECNOLOGIA,
        CNPJ 43.620.859/0001-06. Esta política descreve como coletamos, usamos,
        armazenamos e protegemos seus dados pessoais quando você utiliza nossos
        serviços disponíveis em{" "}
        <a href="https://docs.filipelopes.med.br">docs.filipelopes.med.br</a>.
      </p>
    </section>

    <section>
      <h2>Coleta de Dados</h2>

      <p>
        2.1. Dados do Usuário: Ao se cadastrar em nosso site, coletamos
        informações como nome, email, telefone, CNS, CPF, número e UF do
        documento profissional e dados de pagamento em caso de contas premium.
      </p>

      <p>
        2.2. Dados do Paciente: Armazenamos dados de pacientes como nome, Cartão
        Nacional do SUS (CNS), CPF, peso, altura, altura uterina, frequência de
        batimentos cardiofetais, dados de anamnese e prontuário médico, somente
        se inseridos pelo usuário para fins específicos.
      </p>
    </section>

    <section>
      <h2>Uso e Armazenamento de Dados</h2>

      <p>
        3.1. Segurança: Utilizamos o robusto banco de dados PostgreSQL com
        criptografia padrão para armazenar seus dados. Este banco de dados não
        possui acesso remoto por qualquer porta, garantindo segurança adicional.
      </p>

      <p>
        3.2. Acesso: Os dados dos pacientes são armazenados somente se
        solicitados pelo usuário para salvar prescrições de repetição ou para
        funções como a caderneta da gestante.
      </p>

      <p>
        3.3. Cookies: Utilizamos cookies para monitoramento de acessos e envio
        de propaganda direcionada. Você pode gerenciar ou desativar os cookies
        nas configurações do seu navegador.
      </p>
    </section>

    <section>
      <h2>Uso dos Dados</h2>

      <p>
        4.1. Acesso à Plataforma: Seus dados são utilizados para permitir o
        acesso e utilização da plataforma.
      </p>

      <p>
        4.2. Identificação para Pagamento: Utilizamos seus dados para meios de
        pagamento, especialmente em contas premium, através da empresa terceira
        Stripe.
      </p>

      <p>
        4.3. Comunicação: Podemos enviar novidades sobre a ferramenta, caso você
        opte por receber tais informações.
      </p>
    </section>

    <section>
      <h2>Integração com Serviços Terceiros</h2>

      <p>
        5.1. Plugin SUS: O sistema poderá trocar dados com o Plugin SUS mediante
        integração com serviços terceiros da ORANGO I/O TECNOLOGIA, como{" "}
        <a href="https://meuexa.me">meuexame.app</a> e{" "}
        <a href="https://calc.filipelopes.med.br">calc.filipelopes.med.br</a>,
        através de protocolos de segurança de chave simples, se assim solicitado
        pelo usuário.
      </p>

      <p>
        5.2. API Externa: Utilizamos funções de API externas com dados não
        identificados para garantir segurança na transferência de dados.
      </p>
    </section>

    <section>
      <h2>Acesso e Controle dos Dados</h2>

      <p>
        6.1. Acesso Exclusivo: Os dados cadastrados como prescrições e grupos de
        exame são de acesso exclusivo do usuário e do administrador da
        plataforma.
      </p>

      <p>
        6.2. Dados Públicos: Se você optar por tornar seus dados públicos no
        momento da criação, eles poderão ser acessados por outros usuários.
      </p>
    </section>

    <section>
      <h2>Alterações na Política de Privacidade</h2>

      <p>
        Reservamo-nos o direito de modificar esta Política de Privacidade a
        qualquer momento. Quaisquer alterações serão comunicadas em nosso site
        e, se necessário, solicitaremos seu consentimento novamente.
      </p>
    </section>

    <section>
      <h2>Contato</h2>

      <p>
        Para quaisquer dúvidas sobre esta Política de Privacidade ou para
        exercer seus direitos conforme a Lei Geral de Proteção de Dados, entre
        em contato conosco através do email{" "}
        <a href="mailto:contato@filipelopes.med.br">
          contato@filipelopes.med.br
        </a>
        .
      </p>
    </section>

    <p>Data de Atualização: 23 de Abril de 2024.</p>

    <p>
      Ao utilizar nossos serviços, você concorda com esta Política de
      Privacidade.
    </p>
  </article>
);
