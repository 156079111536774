import styled from "styled-components";

export default styled.main`
  padding-top: 20vh;
  width: 100%;

  form {
    text-align: left;

    > section {
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      padding: 16px;
      margin-top: 1rem;

      > div {
        max-width: 200px;
        margin: 8px auto;

        label {
          display: block;
        }

        input {
          width: 100%;
        }
      }
    }

    button {
      font-size: 1.5rem;
      height: auto;
      padding: 8px 16px;
      margin: 16px auto;
      display: block;
    }
  }

  p {
    text-align: center;

    a {
      color: ${({ theme }) => theme.colors.pink};
    }
  }
`;
