import styled from "styled-components";

export default styled.div`
  height: 100px;
  height: 100px;
  position: fixed;
  top: 10px;
  right: 10px;

  > div {
    background: #fff;
    padding: 0.5rem;
    max-width: 600px;
    box-shadow: 8px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
  }
`;
