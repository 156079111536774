import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.bluePurpleGradient};
  height: 100%;
  width: 90vw;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 32px auto;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid blue;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  color: white;
  box-sizing: border-box;
  position: relative;

  .premium-bar {
    position: absolute;
    top: -1px;
    left: -1px;
    height: 24px;
    width: calc(100% + 2px);
    font-size: 14px;
    color: black;
    background: ${({ theme }) => theme.premiumGoldGradient};
    border-radius: 16px 16px 0 0;
    font-weight: 300;
    text-align: center;
    line-height: 24px;
  }

  div[class*="-option"] {
    color: black;
  }

  > img {
    height: 64px;
    margin-bottom: 8px;
  }

  h1,
  h2 {
    color: white;
    text-align: center;
  }

  main {
    padding-top: 0.5rem;
  }
`;
