import { Container } from "./styles";

import Footer from "components/Footer";
import PropTypes from "prop-types";
import React from "react";

const LoginTemplate = ({ children, premium }) => [
  <Container key="content">
    {premium && (
      <div className="premium-bar">Bem vindo ao Cadastro Premium!</div>
    )}
    <img src="logo192.png" alt="Logo da aplicação" />
    <p>Docs • Filipe Lopes</p>
    {children}
  </Container>,
  <Footer key="footer" />,
];

LoginTemplate.propTypes = {
  children: PropTypes.elementType.isRequired,
  premium: PropTypes.bool,
};

LoginTemplate.defaultProps = {
  premium: false,
};

export default LoginTemplate;
