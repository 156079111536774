import styled, { css } from "styled-components";

export default styled.main`
  padding-top: 20vh;
  display: flex;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;

  ${({ step }) =>
    step === 2
      ? css`
          > article {
            &:first-child {
              > button {
                right: -70%;
                opacity: 0.2;

                @media screen and (max-width: 600px) {
                  right: -60%;
                }
              }
            }
            &:last-child {
              > button {
                left: 0;
                opacity: 1;
              }
            }
          }
        `
      : css`
          > article {
            &:first-child {
              > button {
                right: 0;
                opacity: 1;
              }
            }
            &:last-child {
              > button {
                left: -70%;
                opacity: 0.2;

                @media screen and (max-width: 600px) {
                  left: -60%;
                }
              }
            }
          }
        `}

  > article {
    width: 100%;
    flex-shrink: 0;
    text-align: center;
    box-sizing: border-box;
    padding: 0;

    > button {
      position: relative;
      background: transparent;
      border: none;
      display: inline;
      transition: all 0.4s ease-in-out;
    }
  }

  a.btn-like {
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid white;
    display: inline-flex;
    line-height: 32px;
    font-size: 1rem;
    text-align: center;
    margin-top: 16px;
    color: white;
    text-decoration: none;
    background: rgba(255, 255, 255, 0.2);

    svg {
      height: 32px;
    }

    path,
    polygon,
    rect {
      fill: white;
    }

    &:hover {
      background: white;
      color: ${({ theme }) => theme.colors.pink};
      text-decoration: none;

      path,
      polygon,
      rect {
        fill: ${({ theme }) => theme.colors.pink};
      }
    }
  }

  form {
    text-align: left;

    > section {
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      padding: 16px;
      margin-top: 1rem;

      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.pink};

        &:hover {
          text-decoration: underline;
        }
      }

      > div,
      > section > div {
        max-width: 300px;
        margin-top: 8px;
        margin-right: 8px;

        label {
          display: block;
        }

        input {
          width: 100%;
        }
      }

      > section {
        display: flex;
        align-items: flex-end;
      }
    }

    button {
      font-size: 1.5rem;
      height: auto;
      padding: 8px 16px;
      margin: 16px auto;
      display: block;
    }
  }
`;
