import styled from "styled-components";

export default styled.footer`
  padding: 64px 0 24px 0;
  background: ${({ theme }) => theme.blueGreenGradient};
  color: white;
  width: 100vw;
  margin-top: 2rem;
  padding: 3rem 20vw;
  box-sizing: border-box;
  text-align: center;
  transition: all 0.5 ease;
  border-top: 3px solid blue;

  &.hover {
    border-top: 3px solid green;
  }

  a {
    text-decoration: none;
    color: white;
    display: block;
    line-height: 18px;
  }

  > div:first-child {
    margin-bottom: 3rem;
  }

  .flex {
    justify-content: center;
    flex-wrap: wrap;
  }

  button {
    border: 1px solid white;
    border-radius: 4px;
    padding: 8px 16px;
    background: transparent;
    color: white;
    margin-bottom: 2rem;
    position: relative;

    &:hover {
      background: #61805d;
      color: white;
    }

    svg {
      position: absolute;
      top: 33%;
      left: -29px;
      height: 20px;
      width: auto;
    }
  }

  svg#filipelopesmedbr-logo {
    height: 70px;
    display: inline-block;
    margin-bottom: 4px;
  }

  svg#orango-logo {
    height: 25px;
    width: auto;
  }

  svg#orango-logo,
  svg#orango-logo path {
    fill: white;
  }

  &.hover {
    svg#orango-logo,
    svg#orango-logo path {
      fill: #61805d;
    }
  }

  small {
    position: relative;
    top: -32px;
    opacity: 0.7;
    font-size: 0.6rem;
  }
`;
