import {
  CREATE_OR_UPDATE_PROFESSIONAL,
  GET_PROFESSIONAL,
  GET_STATE,
} from "services/api";
import AppContext from "services/context";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Input from "components/Input";
import Select from "components/Select";
import React, { useContext, useEffect } from "react";

export const professionalSectionInitialValues = {
  professionalName: "",
  professionalUf: "",
  professionalCRM: "",
  professionalCNS: "",
  professionalEmail: "",
};

export const professionalSectionDefaultRequiredFields = {
  name: true,
  uf: true,
  crm: true,
  cns: true,
  email: true,
};

export const professionalSectionDefaultInitialVisibleFields = {
  name: true,
  uf: true,
  crm: true,
  cns: true,
  email: true,
};

export default ({ formik, requiredFields, visibleFields }) => {
  const _requiredFields = {
      ...professionalSectionDefaultRequiredFields,
      ...requiredFields,
    },
    _visibleFields = {
      ...professionalSectionDefaultRequiredFields,
      ...professionalSectionDefaultInitialVisibleFields,
      ...visibleFields,
    },
    { professional, setProfessional, user, acceptedLGPDTerms } =
      useContext(AppContext),
    { data: statesData } = useQuery(GET_STATE),
    [getProfessional, { loading, error, data: professionalData }] =
      useLazyQuery(GET_PROFESSIONAL),
    [createOrUpdateProfessional] = useMutation(CREATE_OR_UPDATE_PROFESSIONAL);

  useEffect(() => {
    if (!user) {
      if (
        formik.values.professionalUf &&
        formik.values.professionalCRM &&
        formik.values.professionalName &&
        formik.values.professionalCNS &&
        formik.values.professionalEmail
      ) {
        console.log("Salvando/atualizando profissional...");
        setProfessional({
          ...professional,
          documentUf: formik.values.professionalUf,
          documentNumber: formik.values.professionalCRM,
          name: formik.values.professionalName,
          cns: formik.values.professionalCNS,
          email: formik.values.professionalEmail,
        });
        createOrUpdateProfessional({
          variables: {
            documentUf: formik.values.professionalUf,
            documentNumber: formik.values.professionalCRM,
            name: formik.values.professionalName,
            cns: formik.values.professionalCNS,
            email: formik.values.professionalEmail,
            lgpd: acceptedLGPDTerms,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.professionalUf,
    formik.values.professionalCRM,
    formik.values.professionalName,
    formik.values.professionalCNS,
    formik.values.professionalEmail,
  ]);

  useEffect(() => {
    if (professional) {
      // Preenche os campos de profissionais automaticamente
      formik.setValues({
        ...formik.values,
        professionalName: professional.name,
        professionalUf: professional.documentUf,
        professionalCRM: professional.documentNumber,
        professionalCNS: professional.cns,
        professionalEmail: professional.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professional]);

  useEffect(() => {
    if (user) {
      formik.setValues({
        ...formik.values,
        professionalName: user.name,
        professionalUf: user.professionalDocumentUf.split(".")[1],
        professionalCRM: user.professionalDocumentNumber,
        professionalCNS: user.cns,
        professionalEmail: user.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    // Caso exista um dado de profissional vindo do banco de dados devemos colocar esses dados no storage local
    if (professionalData?.professional && !professional?.name) {
      setProfessional({
        ...professionalData.professional,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professionalData, loading, error]);

  const fetchProfessional = () => {
    getProfessional({
      variables: {
        documentUf: formik.values.professionalUf,
        documentNumber: formik.values.professionalCRM,
      },
    });
  };

  return (
    <section>
      {_visibleFields.uf && (
        <Select
          name="professionalUf"
          placeholder="UF do CRM"
          formik={formik}
          required={_requiredFields.uf}
          onChange={fetchProfessional}
          options={statesData?.states.map((s) => ({
            label: `${s.name} - ${s.uf}`,
            value: s.uf,
          }))}
        />
      )}
      {_visibleFields.crm && (
        <Input
          name="professionalCRM"
          placeholder="Números do CRM"
          formik={formik}
          required={_requiredFields.crm}
          onBlur={fetchProfessional}
        />
      )}
      {_visibleFields.name && (
        <Input
          name="professionalName"
          placeholder="Nome Completo do médico solicitante"
          formik={formik}
          required={_requiredFields.name}
        />
      )}
      {_visibleFields.cns && (
        <Input
          name="professionalCNS"
          label="Cartão Nacional do SUS do Solicitante"
          formik={formik}
          required={_requiredFields.cns}
        />
      )}
      {_visibleFields.email && (
        <Input
          name="professionalEmail"
          label="Email profissional do Solicitante"
          plaqceholder="Email"
          formik={formik}
          required={_requiredFields.email}
        />
      )}
    </section>
  );
};
