import AppContext from "services/context";
import { b64toBlob } from "services/utils";

import { useContext, useEffect } from "react";
import useErrors from "services/hooks/useErrors";

function useGeneratePdfApiResponse(name, { data, error, loading }) {
  const { setLoading } = useContext(AppContext);

  useEffect(() => {
    console.log(name, data, error, loading);

    if (loading) {
      setLoading(true);
    }
    if (data) {
      setLoading(false);
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const file = b64toBlob(data[name].base64Pdf, "application/pdf");
      if (isMobile) {
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.download = "meddocs.pdf";
        link.click();
      } else {
        const fileWindow = window.open();
        const url = URL.createObjectURL(file);
        fileWindow.document.write(
          "<title>Visualização de Arquivo</title>" +
            '<body style="overflow: hidden; margin: 0">' +
            '<object width="100%" width="-webkit-fill-available" height="100%" height="-webkit-fill-available" type="application/pdf" data="' +
            encodeURI(url) +
            '"></object>' +
            "</body>"
        );
      }
    }
  }, [data, error, loading, name, setLoading]);

  useErrors(error);
}

export default useGeneratePdfApiResponse;
