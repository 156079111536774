import styled from "styled-components";

export default styled.section`
  hr {
    margin: 8px 0;
    opacity: 0.1;
  }
  h2 {
    margin: 8px 0;
    opacity: 0.4;
  }

  > div {
    width: 300px;
    max-width: 300px;
    margin-bottom: 8px;
  }

  .small {
    position: relative;
    bottom: 0;
    left: 0;
  }
`;
