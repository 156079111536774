import {
  GET_EXAMS,
  CREATE_OR_UPDATE_EXAMS_GROUP,
  GET_EXAM_GROUPS,
} from "services/api";
import AppContext from "services/context";

import { useQuery, useMutation } from "@apollo/client";
import Button from "components/Button";
import Private from "components/FC_Private";
import Input from "components/Input";
import FormPageTemplate from "components/LYT_FormPage";
import Select from "components/Select";
import { useFormik } from "formik";
import React, { useEffect, useState, useContext } from "react";

export default () => {
  const [examOptions, setExamOptions] = useState([]),
    [examGroups, setExamGroups] = useState([]),
    [
      createOrUpdateExamGroup,
      {
        loading: createOrUpdateExamGroupLoading,
        data: createOrUpdateExamGroupData,
      },
    ] = useMutation(CREATE_OR_UPDATE_EXAMS_GROUP),
    { data: examsData } = useQuery(GET_EXAMS),
    { data: examsGroupsData } = useQuery(GET_EXAM_GROUPS, {
      variables: {
        owner: true,
      },
    }),
    { setLoading, user } = useContext(AppContext),
    formik = useFormik({
      initialValues: {
        id: null,
        name: "",
        exams: [],
      },
      onSubmit: (values) => {
        console.log(values);
        createOrUpdateExamGroup({ variables: { ...values } });
        formik.resetForm();
      },
    });

  useEffect(() => {
    if (examsData?.exams?.length) {
      setExamOptions(
        examsData.exams.map((e) => ({
          label: e.name,
          value: e.name,
        }))
      );
    }
  }, [examsData]);

  useEffect(() => {
    if (!createOrUpdateExamGroupLoading) {
      setLoading(createOrUpdateExamGroupLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateExamGroupLoading]);

  useEffect(() => {
    if (createOrUpdateExamGroupData) {
      setLoading(false);
      setExamGroups(
        examGroups
          .filter(
            (eg) =>
              eg.id !== createOrUpdateExamGroupData.createOrUpdateExamsGroup.id
          )
          .concat(createOrUpdateExamGroupData.createOrUpdateExamsGroup)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateExamGroupData]);

  useEffect(() => {
    if (examsGroupsData?.examGroups?.length) {
      setExamGroups(examsGroupsData.examGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examsGroupsData]);

  return (
    <Private>
      <FormPageTemplate
        metaTags={{
          title: "Meus grupos de Exames",
        }}
      >
        <main>
          <header>
            <h1>
              <p>Área Premium</p>Meus Grupos de Exames
              <p>
                Crie grupo de Exames para facilitar o processo de solicitação no
                seu consultório
              </p>
            </h1>
            <p className="warning">
              Sugestões ou melhorias, contato no rodapé do site.
            </p>
          </header>
          {user?.isPremium && user?.isActive ? (
            <>
              <section>
                <h2>{formik.values.id ? "Editar" : "Criar"} Grupo de Exames</h2>
                <form onSubmit={formik.handleSubmit}>
                  <section>
                    <input type="hidden" name="id" value={formik.values.id} />
                    <section>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Nome do Grupo"
                        formik={formik}
                        required
                      />
                      <Select
                        name="exams"
                        placeholder="Lista de exames"
                        formik={formik}
                        options={examOptions}
                        required
                        onCreateOption={(value) => {
                          setExamOptions([
                            ...examOptions,
                            { label: value, value: value },
                          ]);
                        }}
                        creatable
                        isMulti
                      />
                    </section>
                  </section>
                  <Button type="submit">
                    {formik.values.id ? "Atualizar" : "Criar"}
                  </Button>
                  {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}
                </form>
              </section>
              <section>
                <h2>Lista de Grupos de Exames</h2>
                {examGroups.map((group) => (
                  <div key={group.name}>
                    <h3>{group.name}</h3>
                    <p>{group.exams.map((e) => e.name).join(", ")}</p>
                    <button
                      onClick={() => {
                        formik.setValues({
                          id: group.id,
                          name: group.name,
                          exams: group.exams.map((e) => e.name),
                        });
                        // sobe a página para o topo
                        window.scrollTo(0, 0);
                      }}
                    >
                      Editar
                    </button>
                    <hr />
                  </div>
                ))}
              </section>
            </>
          ) : (
            <p>
              <br />
              Sua conta está suspensa, revise a seção de Assinatura no Painel
              Lateral para mais detalhes.
            </p>
          )}
          {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}
        </main>
      </FormPageTemplate>
    </Private>
  );
};
