import { GET_ESTABLISHMENTS } from "services/api";
import AppContext from "services/context";

import { useQuery } from "@apollo/client";
import Input from "components/Input";
import Select from "components/Select";
import Tip from "components/Tip";
import React, { useEffect, useState, useContext } from "react";

export const establishmentSectionInitialValues = {
  establishmentName: "",
  establishmentCNES: "",
};

export const establishmentSectionDefaultRequiredFields = {
  name: true,
  cnes: true,
};

export const establishmentSectionDefaultInitialVisibleFields = {
  name: true,
  cnes: true,
};

export default ({ formik, requiredFields, visibleFields }) => {
  const _requiredFields = {
      ...establishmentSectionDefaultRequiredFields,
      ...requiredFields,
    },
    _visibleFields = {
      ...establishmentSectionDefaultRequiredFields,
      ...establishmentSectionDefaultInitialVisibleFields,
      ...visibleFields,
    },
    { data: establishmentsData } = useQuery(GET_ESTABLISHMENTS),
    [establishmentsOptions, setEstablishmentsOptions] = useState([]),
    { user } = useContext(AppContext);

  useEffect(() => {
    if (establishmentsData?.establishments?.length) {
      setEstablishmentsOptions(
        establishmentsData?.establishments?.map((e) => ({
          label: `${e.name} - ${e.cnes}`,
          value: e.name,
          cnes: e.cnes,
        }))
      );
    }
  }, [establishmentsData]);

  useEffect(() => {
    // Adicionando informações iniciais das preferências de usuário, caso presente
    if (user?.preferences?.establishment?.id) {
      formik.setFieldValue(
        "establishmentName",
        user?.preferences?.establishment?.name
      );
      formik.setFieldValue(
        "establishmentCNES",
        user?.preferences?.establishment?.cnes
      );
      formik.setFieldTouched("establishmentName", true, true);
      formik.setFieldTouched("establishmentCNES", true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <div className="info">
        <img
          src="tutorial001.jpeg"
          alt="Ilustração de como adicionar nova instituição"
        />
        <p>
          <strong>Não encontrou sua unidade na lista?</strong> É fácil de
          adicionar. Digite o nome completo de sua Unidade de Saúde no campo
          &ldquo;Nome da Unidade de Saúde&ldquo; abaixo e clique conforme a
          imagem para adicionar. Em seguida preencha o CNES da Unidade e
          prossiga com o preenchimento do formulário! O melhor é que da próxima
          vez o estabelecimento já estará na lista!
        </p>
      </div>
      {!user?.id && (
        <Tip href="/login">
          Ao <strong>Fazer Login</strong> você pode salvar suas preferências da
          Unidade.
        </Tip>
      )}
      {!user?.preferences?.establishment?.id && (
        <Tip>
          Clique no seu nome, no cabeçalho dessa página e entre em Configurações
          para definir sua Unidade de Saúde padrão.
        </Tip>
      )}
      {_visibleFields.name && (
        <Select
          name="establishmentName"
          placeholder="Nome da Unidade de Saúde"
          formik={formik}
          options={establishmentsOptions}
          onChange={(e) => {
            formik.setFieldValue("establishmentCNES", e.cnes);
            formik.setFieldTouched("establishmentCNES", true, true);
          }}
          required={_requiredFields.name}
          onCreateOption={(value) => {
            setEstablishmentsOptions(
              establishmentsOptions.reduce(
                (acc, { label, value, ...cur }) => [
                  ...acc,
                  {
                    label,
                    value,
                    cnes: cur.cnes,
                  },
                ],
                [{ label: value, value: value }]
              )
            );
            formik.setFieldValue("establishmentCNES", "");
            formik.setFieldTouched("establishmentCNES", true, true);
          }}
          creatable
        />
      )}{" "}
      {_visibleFields.cnes && (
        <Input
          name="establishmentCNES"
          formik={formik}
          placeholder="CNES"
          required={_requiredFields.cnes}
        />
      )}
    </section>
  );
};
