import styled from "styled-components";

export default styled.div`
  position: absolute;
  z-index: 10;
  backdrop-filter: blur(24px);
  width: 100%;

  > div {
    width: 320px;
    margin: 0 auto;
    margin-top: 6rem;
    background: ${({ theme }) => theme.bluePurpleGradient};
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    border: 1px solid blue;
    color: white;
  }
`;
