import styled from "styled-components";

export default styled.div`
  width: 100vw;
  background: radial-gradient(
    circle,
    rgba(2, 122, 255, 1) 0%,
    rgba(15, 2, 255, 1) 100%
  );
  color: white;
  text-align: center;
  padding: 0.5rem;
  border-bottom: 1px solid blue;
  box-shadow: inset 0 -2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: showTopBar 2s ease-in-out 0.5s forwards;
  font-size: 0.8rem;
  cursor: pointer;

  small {
    opacity: 0.6;
    display: block;
  }

  button {
    border: 1px solid white;
    background: transparent;
    color: white;
    padding: 2px 8px;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;

    path {
      fill: white;
      fill-opacity: 1;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      background: white;
      color: blue;

      path {
        fill: blue;
      }
    }
  }

  @keyframes showTopBar {
    0% {
      top: -40px;
      height: 0px;
    }

    100% {
      top: 0;
      height: auto;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 0.8rem;

    > div {
      padding: 8px 16px;
    }
  }
`;
