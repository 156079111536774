import StyledSection from "./styles";

import {
  GET_ESTABLISHMENTS,
  GET_STATE,
  UPDATE_PREFERENCES,
  GET_TEMPLATES,
} from "services/api";
import AppContext from "services/context";

import { useQuery, useMutation } from "@apollo/client";
import Input from "components/Input";
import PremiumBadge from "components/PremiumBadge";
import Select from "components/Select";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import React, { useContext, useEffect, useState, useCallback } from "react";

const Settings = () => {
  const { user, setUser } = useContext(AppContext),
    { data: statesData } = useQuery(GET_STATE),
    { data: establishmentsData } = useQuery(GET_ESTABLISHMENTS),
    [establishmentsOptions, setEstablishmentsOptions] = useState([]),
    [updatePreferences, { data: preferencesData }] =
      useMutation(UPDATE_PREFERENCES),
    { data: templatesData } = useQuery(GET_TEMPLATES, {
      variables: { documentName: "exame" },
    }),
    formik = useFormik({
      initialValues: {
        establishmentId: user.preferences?.establishment?.id,
        city: user.preferences?.city,
        stateUf: user.preferences?.state?.uf,
        labTestReason: user.preferences?.labTestReason,
        labTestTemplateId: user.preferences?.labTestTemplate?.id,
      },
      onSubmit: () => {},
    });

  useEffect(() => {
    if (establishmentsData?.establishments?.length) {
      setEstablishmentsOptions(
        establishmentsData?.establishments?.map((e) => ({
          label: `${e.name} - ${e.cnes}`,
          value: e.id,
          cnes: e.cnes,
        }))
      );
    }
  }, [establishmentsData]);

  const handleDebounceFn = (userPreferencesInput) =>
    updatePreferences({
      variables: { userPreferencesInput },
    });

  const debounceUpdatePreferences = useCallback(
    debounce(handleDebounceFn, 2000),
    []
  );

  useEffect(() => {
    // Cada vez que alteramos o formulário as preferências são editadas
    if (
      user.preferences?.establishment?.id !== formik.values.establishmentId ||
      user.preferences?.city !== formik.values.city ||
      user.preferences?.state?.uf !== formik.values.stateUf ||
      user.preferences?.labTestReason !== formik.values.labTestReason ||
      user.preferences?.labTestTemplate?.id !== formik.values.labTestTemplateId
    ) {
      debounceUpdatePreferences(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    if (preferencesData) {
      setUser({
        ...user,
        preferences: preferencesData.createOrUpdateUserPreference,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferencesData]);

  return (
    <StyledSection>
      <hr />
      <h2>Preferências</h2>
      <Select
        placeholder="Selecione um estabelecimento"
        label="Preferência de Estabelecimento"
        name="establishmentId"
        options={establishmentsOptions}
        formik={formik}
      />
      {!user.isPremium && (
        <p>
          As configurações abaixo são apenas para usuários{" "}
          <PremiumBadge type="small" />
        </p>
      )}
      <Select
        placeholder="Selecione um estado"
        label="Preferência de Estado"
        name="stateUf"
        options={statesData?.states.map((s) => ({
          label: `${s.name} - ${s.uf}`,
          value: s.uf,
        }))}
        formik={formik}
        disabled={!user.isPremium}
      />
      <Input
        label="Preferência de Cidade"
        name="city"
        formik={formik}
        disabled={!user.isPremium}
      />
      <Input
        label="Motivo de Solicitação padrão"
        description="Será o preenchimento de motivo inicial, facilitando preenchimento"
        name="labTestReason"
        formik={formik}
        disabled={!user.isPremium}
      />
      <Select
        placeholder="Selecione um template de Exame"
        description="Selecione o template padrão de solicitação de exames"
        name="labTestTemplateId"
        options={templatesData?.templates.map((t) => ({
          label: t.description,
          value: t.id,
        }))}
        formik={formik}
        disabled={!user.isPremium}
      />
      <p>Importante: Atualize a página após as mudanças</p>
      {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}
    </StyledSection>
  );
};

export default Settings;
