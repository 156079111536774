import Portal from "./styles";

import React from "react";

export default ({ children }) => {
  return (
    <Portal>
      <div>{children}</div>
    </Portal>
  );
};
