import MainContainer, { Nav, StyledReactTooltip } from "./styles";

import { ReactComponent as HelpIcon } from "assets/help.svg";

import Button from "components/Button";
import SimplePageTemplate from "components/LYT_SimplePage";
import MenuIcon from "components/MenuIcon";
import React from "react";
import { Link } from "react-router-dom";
export default () => {
  return (
    <SimplePageTemplate>
      <MainContainer>
        <section>
          <h2>Meus Documentos</h2>
          <Nav>
            <MenuIcon
              linkTo="/mamografia"
              icon="mamografia.jpg"
              title="Solicitação de Mamografia"
              description="Documento SUS adicional com dados clínicos para solicitação de mamografia - Programa Nacional de Controle do Câncer de Mama - Ministério da Saúde"
            />
            <MenuIcon
              linkTo="/apac"
              icon="apac.jpg"
              title="Autorização de Procedimentos Ambulatoriais (APAC)"
              description="Documento SUS adicional com dados clínicos para solicitação de mamografia - Programa Nacional de Controle do Câncer de Mama - Ministério da Saúde"
            />
            <MenuIcon
              linkTo="/solicitacao-exames"
              title="Solicitação de Exames - Chequinho do SUS e Outros Templates"
              description="Solicitação de exames do SUS padronizado, você pode solicitar o seu próprio template de documentos"
            />
            <MenuIcon
              linkTo="/receituario"
              title="Receituário Simples (para impressão)"
              description="Em alguns municípios no interior e por meio do sistema SUS ainda não se aceita Assinatura Eletrônica por aqui, essa impressão é uma mão na roda!"
            />
            <MenuIcon
              linkTo="/gestante"
              title="Arquivo da Gestante - Exames e Prescrição"
              description="Aplicação automatizada de Gerar prescrições e solicitações de exames - Ainda em andamento"
            />
            <MenuIcon
              linkTo="/lme"
              icon="lme.jpg"
              title="Laudo de Solicitação, Avaliação e Autorização de Medicamento(s) (LME)"
              description="Documento SUS para dispensação de medicações de alto custo - Uso da Farmácia, preenchimento pelo médico assistente"
              disabled
            />
          </Nav>
        </section>
        <section>
          <h2>Roadmap (Funcionalidades a serem implementadas)</h2>
          <ul>
            <li>
              LME, chequinho do SUS, prescrição padrão para impressão (Água Fria
              - BA)
            </li>
            <li>
              Criação de conta para salvar pacientes e prescrição, bem como
              documentos que faz uso constante (como solicitação de exames,
              APACs, LME)
            </li>
            <li>
              Personalização de Documentos pessoais como Receituários,
              Solicitação de Exames, você poderé solicitar a criação do
              receituário de sua clínica para fácil impressão.
            </li>
          </ul>
          <p>
            Tem alguma sugestão?{" "}
            <a
              href="https://wa.me/5571992518950?text=Ol%C3%A1%2C%20gostaria%20de%20sugerir%20algo%20sobre%20os%20Docs%21"
              target="_blank"
              rel="noreferrer"
            >
              Encaminhe para nós
            </a>
          </p>
        </section>
        <section>
          <h2>Ajude-nos a Progredir</h2>
          <p>
            Estamos desenvolvendo esas aplicações gratuitas para o SUS e
            pequenas empresas, para dar mais produtividade ao médico na vida
            corrida que sei que levamos, com segurança ao paciente e o máximo de
            personalização para melhorar o custo-efetividade. Porém não é barato
            e demanda tempo, precisamos de sua ajuda para continuar oferencend
            atualizações contínuas com melhoria de funcionalidades:
          </p>
          <p
            style={{
              display: "flex",
              justifyContent: "space-between",
              verticalAlign: "middle",
              flex: "1 0 0",
              flexWrap: "wrap",
            }}
          >
            <a
              href="https://donate.stripe.com/bIY7uSaJx4R58IU6oq"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Faça uma doação</Button>
            </a>
            <a
              href="https://wa.me/5571992518950?text=Ol%C3%A1%20Filipe%2C%20gostaria%20de%20te%20ajudar%20a%20desenvolver%20o%20sistema%20de%20Documentos%20M%C3%A9dicos."
              target="_blank"
              rel="noreferrer"
            >
              <Button>Seja um desenvolvedor</Button>
            </a>
            <Link to="/premium">
              <Button>Seja Premium</Button>
            </Link>
            <HelpIcon data-tooltip-id="premium-tooltip" />
            <a
              href="https://wa.me/5571992518950?text=Ol%C3%A1%20Filipe%2C%20gostaria%20de%20criar%20um%20template%20de%20Documentos%20M%C3%A9dicos."
              target="_blank"
              rel="noreferrer"
            >
              <Button>Solicite um Template</Button>
            </a>
            <HelpIcon data-tooltip-id="template-tooltip" />
          </p>
          <StyledReactTooltip
            id="template-tooltip"
            place="top"
            content="Clique para solicitar a construção de um Template para sua solicitação de exames e prescrições com sua logo e identidade visual"
          />
          <StyledReactTooltip
            id="premium-tooltip"
            place="top"
            content="Usuários Premium tem acesso a mais documentos e outras funcionalidades, como salvar pacientes, gestantes e documentos para uso posterior"
          />
        </section>
      </MainContainer>
    </SimplePageTemplate>
  );
};
