import styled from "styled-components";

export default styled.div`
  background: ${({ theme }) => theme.premiumGoldGradient};
  padding: 2px 6px;
  display: inline-block;
  color: black;
  border-radius: 2px;

  &[class*="account-"] {
    background: radial-gradient(
      circle,
      rgba(2, 122, 255, 1) 0%,
      rgba(15, 2, 255, 1) 100%
    );

    span {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  &.medium {
    font-size: 25px;
    margin: 1rem;
    border-radius: 4px;
    padding: 4px 8px;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  span {
    color: black;
  }
`;
