import styled, { css } from "styled-components";

const premiumStyleButton = css`
  background: ${({ theme }) => theme.premiumGoldGradient};
  box-shadow: inset 0 -5px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.3s;

  &:hover {
    box-shadow: inset 0 -5px 0 rgba(0, 0, 0, 0.3),
      inset 0 0 50px rgba(255, 255, 255, 0.5), 0 0 50px rgba(255, 255, 255, 0.5);
  }

  &:active {
    box-shadow: inset 0 5px 0 rgba(0, 0, 0, 0.3),
      inset 0 0 20px rgba(0, 0, 0, 0.3);
  }
`;

export default styled.main`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  button {
    padding: 1rem 2rem;
    font-size: 2rem;
    border: 1px solid black;
    border-radius: 16px;
    margin-top: 32px;

    &:hover {
      box-shadow: 0 0 30px rgba(255, 255, 255, 0.5);
    }
  }

  #planos {
    margin: 8rem auto;

    > h1 {
      margin: 4rem auto;
      text-align: center;
      font-size: 3rem;
    }

    > div {
      display: flex;
      perspective: 900px;

      > div {
        padding: 32px;
        border: 1px solid #2f2f2f;
        border-radius: 16px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        background-color: white;

        &:nth-child(2) {
          background: ${({ theme }) => theme.bluePurpleGradient};
          color: white;
          z-index: 99;

          button {
            ${premiumStyleButton}
          }
        }

        &:nth-child(1) {
          button {
            background: blue;
            color: white;
            box-shadow: inset 0 -5px 0 rgba(0, 0, 0, 0.3);
          }
        }

        h2 {
          margin-bottom: 16px;
          text-align: center;
        }
      }

      @media screen and (max-width: 415px) {
        flex-direction: row;
        flex-wrap: wrap;
        perspective: 1200px;

        > div {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .center {
    max-width: 400px;
    margin: 1rem auto;
    text-align: center;

    h1,
    h2 {
      margin: 2rem auto 1rem auto;
    }

    ul {
      text-align: left;

      li {
        list-style: square;
        margin-bottom: 1rem;
      }
    }

    strong {
      display: block;

      strong {
        font-size: 2rem;
      }
    }

    button:first-of-type {
      font-size: 1rem;
    }

    button.premium-style {
      ${premiumStyleButton}
    }
  }
`;

export const StyledCheckItem = styled.li`
  list-style: none;

  p {
    display: block;
    font-size: 0.8rem;
  }
`;
