import styled, { css } from "styled-components";

export default styled.div`
  padding: 8px 16px;
  position: fixed;
  ${({ position }) =>
    position === "bottom"
      ? css`
          bottom: 32px;
        `
      : css`
          top: 32px;
        `};
  left: 50%;
  background-color: ${({ type }) =>
    type === "info" ? "rgba(66, 66, 255, 0.5)" : "rgba(255, 66, 66, 0.5)"};
  border: 1px solid ${({ type }) => (type === "info" ? "#4242ff" : "red")};
  border-radius: 6px;
  z-index: 99;
  transform: translateX(-50%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);

  ${(props) =>
    props.dotsAnimation &&
    css`
      &:after {
        content: " .";
        animation: dots 1s steps(5, end) infinite;
      }

      @keyframes dots {
        0%,
        20% {
          color: transparent;
          text-shadow: 0.25em 0 0 transparent, 0.5em 0 0 transparent;
        }
        40% {
          color: black;
          text-shadow: 0.25em 0 0 transparent, 0.5em 0 0 transparent;
        }
        60% {
          text-shadow: 0.25em 0 0 black, 0.5em 0 0 transparent;
        }
        80%,
        100% {
          text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
        }
      }
    `}
`;
