import { MSG_001, MSG_003 } from "services/messages";

import * as Yup from "yup";

export default ({
  name,
  motherName,
  cns,
  dob,
  sex,
  phone,
  address: { cep, street, number, complement, neighborhood, city, uf, country },
}) =>
  Yup.object({
    patientName: name ? Yup.string().required(MSG_001) : Yup.string(),
    patientMotherName: motherName
      ? Yup.string().required(MSG_001)
      : Yup.string().nullable(),
    patientCNS: cns ? Yup.string().required(MSG_001) : Yup.string().nullable(),
    patientDob: dob ? Yup.string().required(MSG_001) : Yup.string().nullable(),
    patientSex: sex
      ? Yup.string().oneOf(["MALE", "FEMA"]).required(MSG_001)
      : Yup.string().oneOf(["MALE", "FEMA"]).nullable(),
    patientPhone: phone ? Yup.string().required(MSG_001) : Yup.string(),
    patientAddressUf: uf
      ? Yup.string().length(2, MSG_003(2)).required(MSG_001)
      : Yup.string().length(2, MSG_003(2)),
  });
