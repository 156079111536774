import BlockGlass from "./styles";

import PremiumBadge from "components/PremiumBadge";
import PropTypes from "prop-types";
import React from "react";

const PremiumBlock = ({ style }) => (
  <BlockGlass style={style}>
    <div>
      <p>
        Para acessar essa ferramenta, quando estiver pronta, você terá que ser{" "}
        <PremiumBadge type="medium" />
      </p>

      <p>
        Aproveite 70% de desconto vitalício com o cupom <strong>EARLY70</strong>{" "}
        e garanta essa facilidade no precinho
      </p>
    </div>
  </BlockGlass>
);

PremiumBlock.propTypes = {
  style: PropTypes.object,
};

PremiumBlock.defaultProps = {
  style: {},
};

export default PremiumBlock;
