import { GITLAB_PERSONAL_ACCESS_TOKEN } from "services/env";

import axios from "axios";
import PageTemplate from "components/LYT_SimplePage";
import React from "react";
import { useEffect, useState } from "react";

// create axios client
const client = axios.create({
  baseURL: "https://gitlab.com/api/v4",
  // add header token
  headers: {
    "Content-Type": "application/json",
    "PRIVATE-TOKEN": GITLAB_PERSONAL_ACCESS_TOKEN,
  },
});
export default () => {
  const [bugList, setBugList] = useState([]),
    [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(GITLAB_PERSONAL_ACCESS_TOKEN);
    client
      .get("/groups/67481024/issues?labels=bug&state=opened")
      .then((res) => {
        setBugList(res.data);
        console.log(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <PageTemplate>
      <article>
        <h1>Reportando Erros (Bugs)</h1>
        <section>
          <p>
            Queremos ouvir você! Para tornar nosso serviço online ainda melhor,
            estamos oferecendo isenção da mensalidade para quem encontrar e
            reportar erros funcionais (report) em nossa aplicação. Sabemos que
            seu tempo é precioso e valorizamos muito sua opinião, por isso, essa
            isenção é uma forma de recompensar seu esforço e dedicação. Confira
            os termos:
          </p>
          <ol>
            <li>
              Isenção da Mensalidade: A isenção será aplicada à mensalidade do
              mês vigente em caso de report.
            </li>
            <li>
              Não Cumulativo: A isenção não é cumulativa; se houver múltiplos
              reports no mesmo mês, apenas uma mensalidade será isenta.
            </li>
            <li>
              Novos Reports, Novas Isenções: Em caso de novos reports em meses
              diferentes, a mensalidade daquele mês também será isenta.
            </li>
            <li>
              Elegibilidade: Apenas usuários cadastrados como Premium no momento
              do report são elegíveis.
            </li>
            <li>
              Prioridade do Report: Se dois reports forem sobre o mesmo assunto,
              consideraremos apenas o primeiro.
            </li>
            <li>
              Lista de Erros: O erro reportado não deve estar na lista de erros
              já encontrados.
              {!bugList.length && loading ? (
                <p>Carregando lista...</p>
              ) : (
                <ul>
                  {bugList.map((bug) => (
                    <li key={bug.id}>
                      <h4>{bug.title}</h4>
                      {bug.description}
                    </li>
                  ))}
                </ul>
              )}
            </li>
            <li>
              Envio de Reports: Os reports devem ser enviados pelo link no
              rodapé da página, seguindo a descrição fornecida.
            </li>
            <li>
              Erros Funcionais: Um erro funcional é qualquer problema que impeça
              a aplicação de funcionar corretamente, como falhas no login,
              problemas na navegação, ou funções que não respondem como
              esperado.
            </li>
          </ol>
          <p>
            Agradecemos sinceramente sua ajuda. Sua contribuição não só melhora
            nosso serviço, mas também ajuda toda a comunidade de usuários.
          </p>
          <p>
            Sua contribuição é fundamental para o nosso crescimento! Queremos
            que você se sinta parte da nossa equipe e saiba que suas sugestões e
            feedbacks são sempre bem-vindos. Cada report é uma oportunidade de
            melhorar, e com sua ajuda, podemos alcançar a excelência.{" "}
          </p>
          <p>Muito obrigado por seu apoio e confiança!</p>
        </section>
      </article>
    </PageTemplate>
  );
};
