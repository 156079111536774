import { Container } from "./styles";

import AdsTopBar from "components/AdsTopBar";
import Footer from "components/Footer";
import Header from "components/Header";
import PropTypes from "prop-types";
import React from "react";

const PageTemplate = ({ children, noAds }) => (
  <Container>
    {!noAds && <AdsTopBar />}
    <Header />
    {children}
    <Footer />
  </Container>
);

PageTemplate.propTypes = {
  children: PropTypes.elementType.isRequired,
  noAds: PropTypes.bool,
};

export default PageTemplate;
