import { gql } from "@apollo/client";

export const GET_HELLO_WORLD = gql`
  query HelloWorld {
    hello
  }
`;

export const GET_ESTABLISHMENTS = gql`
  query Establishments {
    establishments {
      id
      name
      cnes
    }
  }
`;

export const GET_STATE = gql`
  query States {
    states {
      name
      uf
    }
  }
`;

export const GET_EXAMS = gql`
  query Exams {
    exams {
      id
      name
    }
  }
`;

export const GET_EXAM_GROUPS = gql`
  query ExamGroups($public: Boolean, $owner: Boolean) {
    examGroups(public: $public, owner: $owner) {
      id
      name
      public
      premium
      login
      exams {
        name
      }
    }
  }
`;

export const GET_TEMPLATES = gql`
  query Templates($documentName: String) {
    templates(documentName: $documentName) {
      id
      name
      description
    }
  }
`;

export const GET_PROFESSIONAL = gql`
  query Professional($documentNumber: String!, $documentUf: String!) {
    professional(documentNumber: $documentNumber, documentUf: $documentUf) {
      name
      documentUf
      documentNumber
      lgpd
      cns
      email
    }
  }
`;

export const GET_CID10 = gql`
  query Cid10($search: String!) {
    cid10(search: $search) {
      code
      description
    }
  }
`;

export const GET_HIGH_COMPLEXITY_PROCEDURES = gql`
  query HighComplexityProcedures($search: String!) {
    highComplexityProcedures(search: $search) {
      id
      code
      name
    }
  }
`;

export const GET_STRIPE_CHECKOUT_DATA = gql`
  query GetStripeCheckoutData($checkout: String!) {
    stripeCheckout(checkout: $checkout) {
      id
      customer
      invoice
      subscription
      customerDetails {
        name
        email
      }
    }
  }
`;

export const GET_STRIPE_SUBSCRIPTIONS = gql`
  query GetStripeSubscription {
    stripeSubscription {
      id
      status
      created
      currentPeriodStart
      currentPeriodEnd
    }
  }
`;

export const GET_STRIPE_CLIENT_PORTAL = gql`
  query GetStripeClientPortal {
    stripeClientPortal
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      token
      user {
        id
        name
        isActive
        isPremium
      }
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      id
      name
      email
      cns
      dob
      isActive
      isPremium
      stripeCustomerId
      professionalDocumentUf
      professionalDocumentType
      professionalDocumentNumber
      preferences {
        establishment {
          id
          name
          cnes
        }
        state {
          uf
        }
        city
        labTestReason
        labTestTemplate {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences($userPreferencesInput: UserPreferenceInput!) {
    createOrUpdateUserPreference(userPreferencesInput: $userPreferencesInput) {
      establishment {
        id
        name
        cnes
      }
      state {
        uf
      }
      city
      labTestReason
      labTestTemplate {
        id
        name
      }
    }
  }
`;

export const CREATE_OR_UPDATE_PROFESSIONAL = gql`
  mutation CreateOrUpdateProfessional(
    $email: String!
    $name: String!
    $cns: String!
    $documentUf: String!
    $documentNumber: String!
    $lgpd: Boolean!
  ) {
    createOrUpdateProfessional(
      cns: $cns
      email: $email
      lgpd: $lgpd
      name: $name
      documentUf: $documentUf
      documentNumber: $documentNumber
    ) {
      name
      lgpd
      email
      documentUf
      documentNumber
      createdAt
      cns
    }
  }
`;

export const CREATE_OR_UPDATE_USER = gql`
  mutation CreateOrUpdateUser($user: UserInput!) {
    createOrUpdateUser(user: $user) {
      id
      email
      name
      isActive
    }
  }
`;

export const CREATE_OR_UPDATE_EXAMS_GROUP = gql`
  mutation CreateOrUpdateExamsGroup(
    $id: Int
    $name: String!
    $exams: [String!]!
  ) {
    createOrUpdateExamsGroup(id: $id, name: $name, exams: $exams) {
      id
      name
      public
      premium
      exams {
        name
      }
      belongsTo {
        id
      }
    }
  }
`;

export const GENERATE_PDF_MAMOGRAFIA = gql`
  mutation PdfMamografia(
    $establishmentName: String!
    $establishmentCNES: String!
    $patientName: String!
    $patientMotherName: String!
    $patientDob: String
    $patientCNS: String!
    $patientSex: SexEnum!
    $professionalName: String!
    $professionalCNS: String!
    $anamnesisHasNodule: noduleLumpEnum!
    $anamnesisHasHighRisk: YesNoDontKnowEnum!
    $anamnesisEvaluatedBreast: YesNoDontKnowEnum!
    $anamnesisBreastTestBefore: YesNoDontKnowEnum!
    $anamnesisLastBreastTestYear: String
    $anamnesisBreastRadiotherapy: RadiotherapyBeforeEnum!
    $anamnesisBreastSurgery_didNot: Boolean = false
    $anamnesisBreastSurgery_leftBreast_biopsiaIncisional: String = null
    $anamnesisBreastSurgery_rightBreast_biopsiaIncisional: String = null
    $clinicalIndicationClinicalExaminationCriteria: DiagnosticMamogramInput = null
    $clinicalIndicationScreeningCriteria: trackingMammogramEnum = null
  ) {
    generatePdfMamografia(
      initialSection: {
        establishment: { name: $establishmentName, cnes: $establishmentCNES }
        patient: {
          name: $patientName
          motherName: $patientMotherName
          birthdate: $patientDob
          sex: $patientSex
          cns: $patientCNS
        }
        requestingProfessional: {
          name: $professionalName
          document: { cns: $professionalCNS }
        }
      }
      anamnesisSection: {
        radiotherapyBefore: { option: $anamnesisBreastRadiotherapy }
        noduleLump: $anamnesisHasNodule
        examinatedBefore: $anamnesisEvaluatedBreast
        mammogramBefore: {
          option: $anamnesisBreastTestBefore
          text: $anamnesisLastBreastTestYear
        }
        breastSurgeryBefore: {
          didNot: $anamnesisBreastSurgery_didNot
          biopsiaIncisional: [
            $anamnesisBreastSurgery_leftBreast_biopsiaIncisional
            $anamnesisBreastSurgery_rightBreast_biopsiaIncisional
          ]
        }
        highRisk: $anamnesisHasHighRisk
      }
      clinicalIndicationSection: {
        diagnosticMammogram: $clinicalIndicationClinicalExaminationCriteria
        trackingMammogram: $clinicalIndicationScreeningCriteria
      }
    ) {
      base64Pdf
    }
  }
`;

export const GENERATE_PDF_APAC = gql`
  mutation PdfAPAC(
    $establishmentName: String!
    $establishmentCNES: String
    $patientName: String!
    $patientMotherName: String!
    $patientDob: String
    $patientCNS: String!
    $patientSex: SexEnum!
    $patientAddressStreet: String
    $patientAddressCity: String
    $patientAddressUf: String
    $patientAddressCep: String
    $patientAddressComplement: String
    $patientAddressNeighborhood: String
    $professionalName: String!
    $professionalCNS: String!
    $mainProcedure: ProcedureApacInput!
    $secondariesProcedures: [ProcedureApacInput!]
    $anamnesisCid10Code: String!
    $anamnesisCid10Description: String!
    $anamnesisSecondaryCid10Code: String = null
    $anamnesisAssociatedCauseCid10Code: String = null
    $anamnesisObservations: String!
  ) {
    generatePdfApac(
      patient: {
        name: $patientName
        motherName: $patientMotherName
        sex: $patientSex
        cns: $patientCNS
        birthdate: $patientDob
        address: {
          street: $patientAddressStreet
          complement: $patientAddressComplement
          neighborhood: $patientAddressNeighborhood
          city: $patientAddressCity
          uf: $patientAddressUf
          zipCode: $patientAddressCep
        }
      }
      requestingEstablishment: {
        name: $establishmentName
        cnes: $establishmentCNES
      }
      requestingProfessional: {
        name: $professionalName
        document: { cns: $professionalCNS }
      }
      mainProcedure: $mainProcedure
      secondariesProcedures: $secondariesProcedures
      procedureJustification: {
        description: $anamnesisCid10Description
        mainCid10: $anamnesisCid10Code
        secCid10: $anamnesisSecondaryCid10Code
        associatedCauseCid10: $anamnesisAssociatedCauseCid10Code
        observations: $anamnesisObservations
      }
    ) {
      base64Pdf
    }
  }
`;

export const GENERATE_PDF_EXAMS = gql`
  mutation PdfExams(
    $patientName: String!
    $reason: String
    $examsList: [String!]!
    $template: ExamRequestTemplateEnum!
    $patientCNS: String
    $patientDob: String
    $patientAddressCity: String
    $patientAddressUf: String
  ) {
    generatePdfSolicitacaoExames(
      patient: {
        name: $patientName
        cns: $patientCNS
        birthdate: $patientDob
        address: { uf: $patientAddressUf, city: $patientAddressCity }
      }
      solicitationReason: $reason
      exams: $examsList
      template: $template
    ) {
      base64Pdf
    }
  }
`;
