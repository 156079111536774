/* eslint-disable jsx-a11y/label-has-associated-control */
import MainContainer from "./styles";

import { LOGIN } from "services/api";
import AppContext from "services/context";

import validationSchema from "./validationSchema";

import { useMutation } from "@apollo/client";
import Button from "components/Button";
import Input from "components/Input";
import LoginTemplate from "components/LYT_LoginPage";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useErrors from "services/hooks/useErrors";

const Login = () => {
  const { setUser, setToken } = useContext(AppContext),
    [login, { error, data: userTokenData }] = useMutation(LOGIN),
    navigate = useNavigate(),
    formik = useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema,
      onSubmit: (values) => {
        login({
          variables: {
            email: values.email,
            password: values.password,
          },
        });
      },
    });

  useErrors(error);

  useEffect(() => {
    if (userTokenData) {
      console.log(userTokenData.signin);
      setToken(userTokenData.signin.token);
      setUser({ ...userTokenData.signin.user });
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTokenData]);

  return (
    <LoginTemplate>
      <MainContainer>
        <form onSubmit={formik.handleSubmit}>
          <section>
            <h2>Login</h2>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              formik={formik}
            />
            <Input
              type="password"
              name="password"
              placeholder="Senha"
              formik={formik}
            />
          </section>
          <Button type="submit">Entrar</Button>
        </form>
        <p>
          Não tem uma conta? <Link to="/cadastro">Cadastre-se</Link>
        </p>
      </MainContainer>
    </LoginTemplate>
  );
};

export default Login;
