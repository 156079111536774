import StyledBar from "./styles";

import AppContext from "services/context";

import PremiumBadge from "components/PremiumBadge";
import React, { useContext } from "react";
const AdsTopBar = () => {
  const { user } = useContext(AppContext);
  return (
    !user?.isPremium && (
      <StyledBar onClick={() => window.open("/premium#planos", "_blank")}>
        <div>
          Aproveite a o{" "}
          <button>
            CUPOM<strong> EARLY70</strong>{" "}
            <svg
              width="16"
              height="8"
              viewBox="0 0 16 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z"
                fill="black"
                fillOpacity="0.54"
              />
            </svg>
          </button>{" "}
          (quantidade LIMITADA!) e adquira o <PremiumBadge type="small" /> por
          70% de desconto e tenha 20%* do seu tempo de volta!
          <small>
            * Dados especulados a partir da prática de usuário da plataforma.
          </small>
        </div>
      </StyledBar>
    )
  );
};

export default AdsTopBar;
