import styled from "styled-components";

export default styled.div`
  color: white;

  a {
    color: ${({ theme }) => theme.colors.pink};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    margin: 16px 0;
    display: flex;
    align-items: center;

    svg {
      height: 12px;
      margin-left: 4px;
    }

    path {
      fill: white;
    }
  }

  hr {
    margin: 8px 0;
    opacity: 0.1;
  }

  h2 {
    margin: 8px 0;
    opacity: 0.4;
  }

  .small {
    position: relative;
    bottom: 0;
    left: 0;
  }

  ul {
    li {
      list-style: inside;
      p {
        opacity: 0.5;
      }
    }
  }
`;
