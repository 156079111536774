import Message from "./styles";

import { useEffect, useRef } from "react";
import React from "react";
export default ({ children, ...rest }) => {
  let timeout = useRef(null);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      if (rest.onClick) rest.onClick();
    }, 5000);
    return clearTimeout(timeout.current);
    // eslint-disable-next-line
  }, []);

  return <Message {...rest}>{children}</Message>;
};
