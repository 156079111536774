import AppContext from "services/context";

import { useContext, useEffect } from "react";

export default (error) => {
  const { setLoading, setSystemMessage } = useContext(AppContext);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
    if (error) {
      console.error(error);
      setSystemMessage({ message: error.message, type: "error" });
    }
  }, [error, setLoading, setSystemMessage]);
};
