import StyledTip from "./styles";

import PropTypes from "prop-types";
import React from "react";
const Tip = ({ href, children }) => {
  return href ? (
    <StyledTip>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <header>
          <span role="img" aria-label="emoji do raio">
            ⚡
          </span>{" "}
          Dica
        </header>
        {children}
      </a>
    </StyledTip>
  ) : (
    <StyledTip>
      <header>
        <span role="img" aria-label="emoji do raio">
          ⚡
        </span>{" "}
        Dica
      </header>
      {children}
    </StyledTip>
  );
};

Tip.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tip;
