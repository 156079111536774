/* eslint-disable jsx-a11y/label-has-associated-control */
import MainContainer from "./styles";

import {
  CREATE_OR_UPDATE_USER,
  GET_STATE,
  GET_STRIPE_CHECKOUT_DATA,
  LOGIN,
} from "services/api";
import AppContext from "services/context";

import validationSchema from "./validationSchema";

import { ReactComponent as StripeLogo } from "assets/stripe-logo.svg";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Button from "components/Button";
import Input from "components/Input";
import LoginTemplate from "components/LYT_LoginPage";
import Select from "components/Select";
import { useFormik } from "formik";
import { pick } from "lodash";
import debounce from "lodash/debounce";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

const Login = () => {
  const { professional, setUser, user, setToken } = useContext(AppContext),
    mainRef = useRef(null),
    [searchParams] = useSearchParams(),
    [premiumForm, setPremiumForm] = useState(false),
    [checkoutSessionId, setCheckoutSessionId] = useState(null),
    [promoCode, setPromoCode] = useState(""),
    [formStep, setFormStep] = React.useState(1),
    [incompleteSignUpUserId, setIncompleteSignUpUserId] = useState(null),
    [createOrUpdateUser, { data: createOrUpdateUserData }] = useMutation(
      CREATE_OR_UPDATE_USER
    ),
    [login, { data: userTokenData }] = useMutation(LOGIN),
    navigate = useNavigate(),
    [getStripeCheckoutData, { data: stripeCheckoutData }] = useLazyQuery(
      GET_STRIPE_CHECKOUT_DATA
    ),
    { data: statesData } = useQuery(GET_STATE),
    formik = useFormik({
      initialValues: {
        email: "",
        name: "",
        cns: "",
        password: "",
        confirmPassword: "",
        professionalDocumentUf: "",
        professionalDocumentNumber: "",
      },
      validationSchema,
      onSubmit: (values) => {
        setUser({
          email: values.email,
        });
        if (premiumForm) {
          // caso seja premium, vai para o step 2 de pagamento
          setFormStep(2);
        } else {
          // caso não seja conclui cadastro
          createOrUpdateUser({
            variables: {
              user: pick(values, [
                "email",
                "name",
                "cns",
                "professionalDocumentUf",
                "professionalDocumentNumber",
                "password",
              ]),
            },
          });
        }
        // caso não seja vai para a parte de
        setFormStep(2);
      },
    }),
    // Atualiza usuário que está em preenchimento
    handleDebounceFn = (user) => {
      console.log(formik.dirty, formik.isValid);
      const userButPassword = pick(user, [
        "email",
        "name",
        "cns",
        "professionalDocumentUf",
        "professionalDocumentNumber",
      ]);
      if (formik.values.name && formik.dirty && formik.isValid) {
        // verifica se o formik está preenchido e sem erros
        // caso esteja com tudo preenchido já, sem erros, salva no banco de dados
        console.log(
          "Todos os dados do usuário já estão nos campos, criando usuário temporário no banco de dados..."
        );
        console.log(user);
        createOrUpdateUser({
          variables: { user: userButPassword },
        });
        // Remove armazenamento local
        localStorage.removeItem("docsfilipelopesmedbr:signup");
      } else if (formik.dirty && !formik.isValid) {
        // em caso de preenchimento parcial apenas com erros, salva apenas no navegador e não no banco de dados
        console.log("Armazenando os dados do usuário no navegador...");
        localStorage.setItem(
          "docsfilipelopesmedbr:signup",
          JSON.stringify(userButPassword)
        );
      }
    },
    debounceUpdateUser = useCallback(debounce(handleDebounceFn, 500), [
      formik.dirty,
      formik.isValid,
    ]);

  useEffect(() => {
    debounceUpdateUser(
      pick(formik.values, [
        "email",
        "name",
        "cns",
        "password",
        "professionalDocumentUf",
        "professionalDocumentNumber",
      ])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, formik.dirty, formik.isValid]);

  useEffect(() => {
    console.log(createOrUpdateUserData);
    if (createOrUpdateUserData?.createOrUpdateUser?.isActive) {
      // Com o cadastro concluído realizamos, solicitando token
      console.log("Realizando login...");
      login({
        variables: {
          email: formik.values.email,
          password: formik.values.password,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateUserData]);

  useEffect(() => {
    if (userTokenData) {
      console.log(userTokenData.signin);
      setToken(userTokenData.signin.token);
      setUser({ ...userTokenData.signin.user });
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTokenData]);

  useEffect(() => {
    const localUserData = JSON.parse(
      localStorage.getItem("docsfilipelopesmedbr:signup")
    );
    if (!(user?.id && !user?.isPremium && premiumForm)) {
      if (localUserData && Object.keys(localUserData).length > 0) {
        console.log("Preenchendo dados iniciais de formulário...");
        console.log(localUserData);
        Object.entries(localUserData).forEach(([key, value]) => {
          formik.setFieldValue(key, value);
          formik.setFieldTouched(key, true, true);
        });
        setIncompleteSignUpUserId(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(user);
    if (user?.id && !user?.isPremium && premiumForm) {
      console.log("Usuário já logado, preenchendo dados...");
      // Verifica se já temos um usuário logado que não é premium em formulário para migrar de plano, preenchemos os campos com dados de usuário existente
      formik.setFieldValue("email", user.email);
      formik.setFieldValue("name", user.name);
      formik.setFieldValue("cns", user.cns);
      formik.setFieldValue(
        "professionalDocumentUf",
        user.professionalDocumentUf.split(".")[1]
      );
      formik.setFieldValue(
        "professionalDocumentNumber",
        user.professionalDocumentNumber
      );
      setIncompleteSignUpUserId(user.id);
      formik.setFieldTouched("email", true, true);
      formik.setFieldTouched("name", true, true);
      formik.setFieldTouched("cns", true, true);
      formik.setFieldTouched("professionalDocumentUf", true, true);
      formik.setFieldTouched("professionalDocumentNumber", true, true);
      // Muda o step
      setFormStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    // Rolando a página horizontalmente em caso de mudança de step
    const width = mainRef.current.clientWidth;
    mainRef.current.scrollTo(width * (formStep - 1), 0);
  }, [formStep]);

  useEffect(() => {
    if (professional) {
      // Preenche os campos de profissionais automaticamente
      formik.setValues({
        ...formik.values,
        name: professional.name,
        professionalDocumentUf: professional.documentUf,
        professionalDocumentNumber: professional.documentNumber,
        cns: professional.cns,
        email: professional.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professional]);

  useEffect(() => {
    // Verificando os parâmetros da url e setando variáveis de estadoda página/formulário baseado nelas
    if (searchParams.get("plan") === "premium") setPremiumForm(true);
    if (searchParams.get("promocode"))
      setPromoCode(searchParams.get("promocode"));
    if (searchParams.get("userId"))
      setIncompleteSignUpUserId(searchParams.get("userId"));
    if (searchParams.get("checkout"))
      setCheckoutSessionId(searchParams.get("checkout"));
  }, [searchParams]);

  useEffect(() => {
    if (checkoutSessionId) {
      // Estamos na página de checkout
      setIncompleteSignUpUserId(null);
      getStripeCheckoutData({
        variables: {
          checkout: checkoutSessionId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutSessionId]);

  useEffect(() => {
    if (stripeCheckoutData) {
      // Cadastramos o customer na entidade do usuário
      createOrUpdateUser({
        variables: {
          user: {
            email: stripeCheckoutData?.stripeCheckout?.customerDetails.email,
            stripeCustomerId: stripeCheckoutData?.stripeCheckout?.customer,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeCheckoutData]);

  return [
    incompleteSignUpUserId && (
      <center
        key="advertisment"
        style={{ margin: "0 auto", width: "90vw", maxWidth: "680px" }}
      >
        <p className="warning">
          Notamos que você não concluiu seu cadastro premium, falta pouco.
          Conclua o formulário a seguir.{" "}
          <small style={{ display: "block" }}>
            Caso queira desistir de ser premium entre em{" "}
            <a
              href="mailto:contato@filipelopes.med.br?subject=Cancelamento de Cadastro Premium&body=Meu email de cadastro é"
              target="_blank"
              rel="noreferrer"
            >
              contato@filipelopes.med.br
            </a>{" "}
            para procedermos com o cancelamento.
          </small>
        </p>
      </center>
    ),
    <LoginTemplate key="content" premium={premiumForm}>
      <MainContainer ref={mainRef} step={formStep}>
        {!checkoutSessionId && (
          <article>
            <button onClick={() => setFormStep(1)}>
              <h1>Cadastro</h1>
            </button>
            <form onSubmit={formik.handleSubmit}>
              <section>
                <center>
                  Ao preencher o cadastro você concorda com os{" "}
                  <Link to={"/termos-de-uso"} target="_blank">
                    Termos de Uso
                  </Link>{" "}
                  e{" "}
                  <Link to={"/politica-privacidade"} target="_blank">
                    Políticas de Privacidade
                  </Link>{" "}
                  da aplicação.
                </center>
              </section>
              <section>
                <header>
                  <h2>Dados Pessoais</h2>
                </header>
                <Input
                  type="text"
                  name="name"
                  placeholder="Nome Completo"
                  formik={formik}
                  required
                />
                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  formik={formik}
                  required
                />
                <Input
                  type="text"
                  name="cns"
                  label="Cartão Nacional do SUS (CNS)"
                  formik={formik}
                  required
                />
                <section>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Senha"
                    formik={formik}
                    required
                  />
                  <Input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirmar Senha"
                    formik={formik}
                    required
                  />
                </section>
              </section>
              <section>
                <header>
                  <h2>Documento Profissional</h2>
                </header>
                <section>
                  <Select
                    name="professionalDocumentUf"
                    placeholder="UF do CRM"
                    formik={formik}
                    options={statesData?.states.map((s) => ({
                      label: `${s.name} - ${s.uf}`,
                      value: s.uf,
                    }))}
                    required
                  />
                  <Input
                    name="professionalDocumentNumber"
                    placeholder="Números do CRM"
                    formik={formik}
                    required
                  />
                </section>
                {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}
              </section>
              <Button type="submit">
                {premiumForm ? "Ir para Pagamento" : "Concluir Cadastro"}
              </Button>
            </form>
          </article>
        )}
        {premiumForm && !checkoutSessionId && (
          <article>
            <button onClick={() => setFormStep(2)}>
              <h1>Pagamento</h1>
            </button>
            <form>
              <section>
                <p>
                  Para proceder para o pagamento clique abaixo para ser
                  redirecionado à zona segura de pagamento com nosso parceiro
                  Stripe. Te vemos num instante!
                </p>
                <a
                  href={`https://buy.stripe.com/test_8wM6sd6O3365bNm288?prefilled_email=${encodeURIComponent(
                    formik.values.email
                  )}&prefilled_promo_code=${encodeURIComponent(
                    promoCode
                  )}&client_reference_id=${user?.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-like"
                >
                  Seguir para Pagamento <StripeLogo />
                </a>
                <small></small>
              </section>
            </form>
          </article>
        )}
        {checkoutSessionId && (
          <article>
            <h1>Aproveite sua Conta</h1>
            <br />
            <p>
              Parabéns{" "}
              <strong>
                {stripeCheckoutData?.stripeCheckout?.customerDetails.name}
              </strong>
              ! Não disse que nos veríamos logo? Agora você pode usufruir dos
              benefícios da conta Premium!
            </p>
            <Link to="/login" className="btn-like">
              Ir para Login
            </Link>
          </article>
        )}
      </MainContainer>
    </LoginTemplate>,
  ];
};

export default Login;
