import { Tooltip as ReactTooltip } from "react-tooltip";
import styled from "styled-components";

export default styled.main`
  max-width: 800px;

  section {
    margin-bottom: 2rem;

    h2 {
      margin-bottom: 1.2rem;
    }
  }

  p {
    margin-top: 2rem;
  }

  @media screen and (max-width: 800px) {
    section {
      margin: 0 16px 2rem 16px;
    }
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 200px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 20px;
  background-color: rgba(0, 0, 0, 0.6) !important;
  backdrop-filter: blur(10px);

  p {
    margin: 0;
    padding: 0;
  }
`;
