import { MSG_001, MSG_002, MSG_004 } from "services/messages";

import * as Yup from "yup";

export default Yup.object().shape({
  email: Yup.string().email(MSG_002).required(MSG_001),
  name: Yup.string().required(MSG_001),
  cns: Yup.string().required(MSG_001),
  password: Yup.string().required(MSG_001),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], MSG_004)
    .required(MSG_001),
  professionalDocumentUf: Yup.string().required(MSG_001),
  professionalDocumentNumber: Yup.string()
    .min(4, "Deve ter no mínimo 4 digitos")
    .required(MSG_001),
});
