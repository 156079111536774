import React from "react";

export default () => {
  return (
    <article>
      <h1>Termos de Uso</h1>
      <section>
        <h2>1. Introdução</h2>
        <p>
          Bem-vindo ao Docs - Filipe Lopes, um sistema desenvolvido pela ORANGO
          I/O TECNOLOGIA (CNPJ 43.620.859/0001-06) para facilitar a geração de
          documentos. Ao utilizar o Docs - Filipe Lopes, você concorda com estes
          Termos de Uso.
        </p>
      </section>

      <section>
        <h2>2. Cadastro e Conta</h2>
        <p>
          O cadastro no Docs - Filipe Lopes é gratuito e pode ser feito no site{" "}
          <a href="https://docs.filipelopes.med.br">docs.filipelopes.med.br</a>.
          Para alguns documentos, o cadastro não é necessário, mas para outros,
          você precisará fornecer informações do profissional, que serão
          armazenadas para facilitar o uso da ferramenta e enviar novidades,
          caso você permita.
        </p>
        <p>
          Ao se cadastrar com e-mail e senha, você terá acesso a recursos
          adicionais, conforme descrito na página de planos. Você também pode
          optar por uma conta premium, mediante assinatura mensal. Os dados de
          pagamento e sua efetivação são gerenciados pela Stripe, uma empresa
          especializada em pagamentos online.
        </p>
      </section>

      <section>
        <h2>3. Uso do Sistema</h2>
        <p>
          Docs - Filipe Lopes é um sistema em beta, o que significa que ainda
          está em desenvolvimento e pode apresentar bugs. Caso você encontre
          algum problema, entre em contato conosco pelo e-mail{" "}
          <a href="mailto:bugs@filipelopes.med.br">bugs@filipelopes.med.br</a>.
        </p>
        <p>
          O sistema tem um uptime de 70%, o que significa que estará disponível
          70% do tempo. Não nos responsabilizamos por falhas de conexão ou
          indisponibilidade do serviço. Envidamos esforços para manter a
          plataforma disponível, mas não garantimos a disponibilidade integral.
        </p>
      </section>

      <section>
        <h2>4. Valores e Regras</h2>
        <p>
          O uso do Docs - Filipe Lopes é gratuito para alguns documentos. Para
          outros documentos, é necessário adquirir uma conta premium, mediante
          pagamento mensal. Os valores dos planos podem ser reajustados a
          qualquer momento, após um ano de uso, sem aviso prévio.
        </p>
        <p>
          Você é responsável por utilizar o Docs - Filipe Lopes de acordo com a
          lei e com estes Termos de Uso. Não nos responsabilizamos pelo mau uso
          da ferramenta.
        </p>
      </section>

      <section>
        <h2>5. Encerramento de Conta</h2>
        <p>
          Reservamo-nos o direito de encerrar ou bloquear contas que violem
          estes Termos de Uso ou que causem danos à plataforma ou a outros
          usuários.
        </p>
      </section>

      <section>
        <h2>6. Alterações nos Serviços</h2>
        <p>
          Os recursos da conta Premium estão incompletos e ainda em fase beta e
          serão liberados à medida que forem desenvolvidos e corrigidos a partir
          do momento que formos notificados sobre os erros pelos canais oficiais
          de comunicação.Podemos modificar ou descontinuar qualquer recurso ou
          serviço a qualquer momento, sem aviso prévio.
        </p>
      </section>

      <section>
        <h2>7. Atualizações dos Termos</h2>
        <p>
          Estes Termos de Uso podem ser atualizados a qualquer momento. As
          alterações entrarão em vigor na data de publicação no site{" "}
          <a href="https://docs.filipelopes.med.br">docs.filipelopes.med.br</a>.
        </p>
      </section>

      <section>
        <h2>8. Disposições Gerais</h2>
        <p>
          Estes Termos de Uso constituem o acordo completo entre você e a ORANGO
          I/O TECNOLOGIA sobre o uso do Docs - Filipe Lopes. Se alguma
          disposição destes Termos for considerada inválida ou ineficaz, ela
          será substituída pela disposição válida mais próxima em termos de seu
          objetivo.
        </p>
        <p>
          Se você tiver alguma dúvida sobre estes Termos de Uso, você pode
          enviar um email para o endereço{" "}
          <a href="mailto:contato@filipelopes.med.br">
            contato@filipelopes.med.br
          </a>
          .
        </p>
        <p>Obrigado por usar o Docs - Filipe Lopes!</p>
      </section>
    </article>
  );
};
