import StyledSection from "./styles";

import { GET_STRIPE_CLIENT_PORTAL } from "services/api";
import AppContext from "services/context";

import { useLazyQuery } from "@apollo/client";
import PremiumBadge from "components/PremiumBadge";
import React, { useContext } from "react";
import { useEffect, useState } from "react";

const RoadmapList = () => (
  <ul>
    <li>
      Cadastros de prescrição e LME de Repetição{" "}
      <p>
        Muitas vezes precisamos imprimir um mesmo documento com certa
        periodicidades, porque não armazená-las e apenas atualizar
        automaticamente quando for preciso reimprimir?
      </p>
    </li>
    <li>
      Copiar prescrição e condutas realizadas no Docs para prontuário facilmente
    </li>
    <li>
      Novos documentos públicos, como orientações nutricionais e comportamentais
      em doenças crônicas
    </li>
    <li>Caderneta da gestante online</li>
    <li>
      Prescrição/Recomendações de Aplicativos e Links Relevantes para o Paciente
    </li>
    <li>
      Biblioteca pública de grupo de exames e medicações aumentadas, inclusive
      fitoterápicos
    </li>
    <li>Prescrição infantil com calculo de dose</li>
    <li>
      Solicitação de Templates próprios
      <p>
        Nossos usuários premium poderão solicitar seus próprios templates de
        prescrição e de Solicitação de Exames. Nossa equipe de designer estará
        pronto para atender a demanda em até 30 dias, sob cobrança adicional a
        ser negociada
      </p>
    </li>
  </ul>
);
const Plan = () => {
  const { user } = useContext(AppContext),
    [getStripeClientPortal, { data }] = useLazyQuery(GET_STRIPE_CLIENT_PORTAL),
    [stripeClientPortalLink, setStripeClientPortalLink] = useState(null);

  useEffect(() => {
    if (user?.isPremium) {
      getStripeClientPortal();
    }
  }, [getStripeClientPortal, user]);

  useEffect(() => {
    if (data?.stripeClientPortal) {
      setStripeClientPortalLink(data.stripeClientPortal);
    }
  }, [data]);

  return !user?.isPremium ? (
    <StyledSection>
      <hr />
      <h2>Você tem uma conta Básica</h2>
      <p>
        Seja <PremiumBadge type="small" /> e aproveite mais benefícios com a
        promoção limitada de 70% de desconto com o CUPOM EARLY70
      </p>
      <h2>Benefícios adicionais Premium Atual</h2>
      <ul>
        <li>Criação de grupo de Exames</li>
        <li>
          Poupe tempo de preenchimento de formulários com criação de documentos
          configurando campos padrão
        </li>
        <li>
          Sendo Premium desde já você garante os benefícios vindouros pelo mesmo
          valor promocional
        </li>
      </ul>
      <h2>Benefícios que serão adicionados</h2>
      <RoadmapList />
    </StyledSection>
  ) : (
    <StyledSection>
      <a href={stripeClientPortalLink} target="_blank" rel="noreferrer">
        {Boolean(user.isPremium && !user.isActive) && (
          <p className="warning">
            A sua conta precisa de atenção! Clique no botão abaixo para mais
            detalhes
          </p>
        )}
        <button>
          Gerir Assinatura (Stripe){" "}
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 2V16H16V9H18V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H9V2H2ZM11 2V0H18V7H16V3.41L6.17 13.24L4.76 11.83L14.59 2H11Z"
              fill="black"
            />
          </svg>
        </button>
      </a>
      <h2>Em breve teremos mais recursos:</h2>
      <RoadmapList />
      <h2>Sugestão de recursos?</h2>
      <p>
        Mande um email para{" "}
        <a href="mailto:contato@filipelopes.med.br?subject=Sugestão%20de%20recursos%20para%20Docs%20-%20Filipe%20Lopes&body=Olá,%0A%0Atenho%20a%20seguinte%20sugestão%20de%20recursos%20para%20o%20Docs%20-%20Filipe%20Lopes:%0A%0A">
          contato@filipelopes.med.br
        </a>{" "}
      </p>
    </StyledSection>
  );
};

export default Plan;
