import Container from "./styles";

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const PremiumBadge = ({ type }) => {
  return type === "small" ? (
    <Container className={type}>
      <Link to="/premium">
        <span>Premium</span>
      </Link>
    </Container>
  ) : type === "medium" ? (
    <Container className={type}>
      <Link to="/premium">
        <span>Premium</span>
      </Link>
    </Container>
  ) : type === "account-small" ? (
    <Container className="account-small">
      <Link to="/login">
        <span>Fazer Login</span>
      </Link>
    </Container>
  ) : (
    <Container>Premium</Container>
  );
};

PremiumBadge.propTypes = {
  type: PropTypes.string,
};

export default PremiumBadge;
