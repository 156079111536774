import Header from "./styles";

import AppContext from "services/context";

import Aside from "components/Aside";
import { useContext, useEffect, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";

export default () => {
  const { professional, user } = useContext(AppContext),
    [name, setName] = useState(null),
    [menuIsOpen, setMenuIsOpen] = useState(false),
    retrieveFirstAndLastName = (string) => {
      return `${string.split(" ")[0]} ${
        string.split(" ")[string.split(" ").length - 1]
      }`;
    };

  useEffect(() => {
    if (user) {
      console.log(user);
      setName(retrieveFirstAndLastName(user.name));
    } else if (professional?.name) {
      setName(retrieveFirstAndLastName(professional.name));
    }
    // eslint-disable-next-line
  }, [user, professional])

  return [
    <Header key="header">
      <h2>
        <Link to="/">
          <strong>
            <img src="logo192.png" alt="Logo da aplicação" />
            Documentos Médicos
          </strong>
        </Link>{" "}
        por{" "}
        <a
          href="https://www.linkedin.com/in/filipelopes-med-br/"
          target="_blank"
          rel="noreferrer"
        >
          Filipe Lopes (Médico/Programador)
        </a>
      </h2>
      <p>
        {name && (user?.name || professional?.name) && (
          <button
            className={`${user?.isPremium && "premium"} ${
              user?.isPremium === false && "login"
            }`}
            onClick={() => setMenuIsOpen(!menuIsOpen)}
          >
            Olá, {name}
          </button>
        )}
        {!user?.name && (
          <Link to="/login">
            <button>Login</button>
          </Link>
        )}
      </p>
    </Header>,
    <Aside
      key="aside"
      menuIsOpen={menuIsOpen}
      setMenuIsOpen={setMenuIsOpen}
      name={name}
    />,
  ];
};
