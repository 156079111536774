import { MSG_001 } from "services/messages";

import * as Yup from "yup";

export default ({ name, uf, crm, cns, email }) =>
  Yup.object({
    professionalName: name ? Yup.string().required(MSG_001) : Yup.string(),
    professionalUf: uf ? Yup.string().required(MSG_001) : Yup.string(),
    professionalCRM: crm ? Yup.string().required(MSG_001) : Yup.string(),
    professionalCNS: cns ? Yup.string().required(MSG_001) : Yup.string(),
    professionalEmail: email ? Yup.string().required(MSG_001) : Yup.string(),
  });
