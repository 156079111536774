import React from "react";
import { Routes, Route } from "react-router-dom";
import DeclineLGPD from "views/DeclineLGPD";
import ExamGroups from "views/ExamGroups";
import Login from "views/Login";
import Main from "views/Main";
import PdfAPAC from "views/PdfAPAC";
import PdfExames from "views/PdfExames";
import PdfGestante from "views/PdfGestante";
import PdfMamografia from "views/PdfMamografia";
import PdfReceituario from "views/PdfReceituario";
import Premium from "views/Premium";
import PrivacyPolicy from "views/PrivacyPolicy";
import ReportBug from "views/ReportBug";
import SignUp from "views/SignUp";
import TermsOfUse from "views/TermsOfUse";

export default () => {
  return (
    <Routes>
      <Route exact path="/" element={<Main />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/cadastro" element={<SignUp />} />
      <Route exact path="/decline-lgpd" element={<DeclineLGPD />} />
      <Route exact path="/apac" element={<PdfAPAC />} />
      <Route exact path="/mamografia" element={<PdfMamografia />} />
      <Route exact path="/lme" element={<PdfMamografia />} />
      <Route exact path="/solicitacao-exames" element={<PdfExames />} />
      <Route exact path="/receituario" element={<PdfReceituario />} />
      <Route exact path="/gestante" element={<PdfGestante />} />
      <Route exact path="/premium" element={<Premium />} />
      <Route exact path="/termos-de-uso" element={<TermsOfUse />} />
      <Route exact path="/reportar-erro" element={<ReportBug />} />
      <Route exact path="/politica-privacidade" element={<PrivacyPolicy />} />
      <Route exact path="/meus-exames" element={<ExamGroups />} />
    </Routes>
  );
};
