import Modal from "./styles";

import Button from "components/Button";
import React from "react";
import { Link } from "react-router-dom";

export default ({ onAccept, onDecline }) => {
  return (
    <Modal>
      <p>
        Cumprindo a Lei Geral de Proteção de Dados (LGPD), informamos que esse
        serviço (site) coleta dados pessoais como nome, documento de título, UF,
        Cartão Nacional do SUS e email do prescritor, além de localização
        aproximada por IP, mas não do paciente para fins de facilitar
        preenchimento de documentos, personalizando o serviço para você,
        avaliação de métricas de uso.{" "}
        <strong>
          Não compartilhamos seus recursos com terceiros, não salvamos dados de
          pacientes
        </strong>
      </p>
      <p>
        Ao navegar nesse site e preencher os formulários, você concorda com os
        termos de uso do programa.
      </p>{" "}
      <Link to="/decline-lgpd">
        <Button className="secondary" onClick={onDecline}>
          Me tire daqui!
        </Button>
      </Link>
      &nbsp;
      <Button onClick={onAccept}>Li e Aceito os Termos de Uso</Button>
    </Modal>
  );
};
