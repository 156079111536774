import styled, { css } from "styled-components";

export default styled.aside`
  position: fixed;
  top: 10vh;
  right: 16px;
  height: 80vh;
  width: ${({ width }) => `${width}px`};
  overflow-y: auto;
  background: ${({ theme }) => theme.bluePurpleGradient};
  transition: all 0.5s;
  z-index: 9;
  border-radius: 8px;
  border: 1px solid blue;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.5);
  color: white;

  div[class*="-option"] {
    color: black;
  }

  p {
    font-weight: 200;
    font-size: 0.8rem;
    margin: 8px 0;
  }

  ul {
    margin-top: 16px;

    li {
      list-style: none;
      margin-top: 8px;

      button {
        width: 100%;
        text-align: left;
      }
    }
  }

  button {
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    background: transparent;
    padding: 4px 8px;

    path {
      fill: white;
    }

    &:hover {
      background: white;
      color: blue;

      path {
        fill: blue;
      }
    }
  }

  .medium {
    margin: 0 auto;
    display: block;
    text-align: center;
  }

  .small {
    position: absolute;
    bottom: 16px;
    left: calc(50% - 32px);
  }

  header {
    button {
      border: none;
    }

    > button {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }

  ${({ open }) =>
    open
      ? css`
          opacity: 1;
          right: 16px;
        `
      : css`
          opacity: 0;
          right: ${({ width }) => `-${width + 16}px`};
        `}
`;
