import MenuIconContainer from "./styles";

import { ReactComponent as OpenNewTabIcon } from "assets/open-new-tab.svg";

import Button from "components/Button";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const MenuIcon = ({ linkTo, icon, title, description, disabled }) => (
  <Link to={linkTo} onClick={(e) => disabled && e.preventDefault()}>
    <MenuIconContainer background={icon} disabled={disabled}>
      <div>
        <div className="menuIcon_background"></div>
        <div className="menuIcon_cover"></div>
        <div className="menuIcon_title">{title}</div>
        <div className="menuIcon_description">
          <p>{description}</p>
          <Link to={linkTo}>
            <Button>
              Ir Para Formulário <OpenNewTabIcon />
            </Button>
          </Link>
        </div>
      </div>
    </MenuIconContainer>
  </Link>
);

MenuIcon.propTypes = {
  linkTo: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
};

MenuIcon.defaultProps = {
  linkTo: "#",
  icon: "default_doc_icon.jpg",
  disabled: false,
};

export default MenuIcon;
