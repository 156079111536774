import SimplePageTemplate from "components/LYT_SimplePage";
import React from "react";

export default () => {
  return (
    <SimplePageTemplate>
      <main>
        Infelizmente não conseguiremos continuar, pois é necessário aceitar os
        termos do produto para continuar o uso, mas foi bom ter você por aqui,
        se mudar de ideia estaremos à disposição!
      </main>
    </SimplePageTemplate>
  );
};
