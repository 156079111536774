import AppContext from "services/context";

import { useEffect, useContext } from "react";

export default (updateFormValues) => {
  const { setShowLGPDTerms, acceptedLGPDTerms } = useContext(AppContext);

  useEffect(() => {
    if (!acceptedLGPDTerms) setShowLGPDTerms(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFormValues]);
};
