import styled from "styled-components";

export default styled.header`
  margin: 1rem auto 2rem auto;

  h2 {
    text-align: right;
    line-height: 1rem;
    vertical-align: super;
    font-size: 1rem;
    margin-bottom: 0.5rem;

    strong {
      background-color: #0000ff;
      color: #fff;
    }

    a {
      color: #0000ff;
    }
  }

  p {
    text-align: right;
    display: block;
  }

  button {
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    border: 1px solid blue;
    color: blue;
    margin-left: 4px;

    &.premium {
      background: ${({ theme }) => theme.premiumGoldGradient};
      color: black;
      border: none;
      box-shadow: 0 0 10px #d6b026;
    }

    &.login {
      background: ${({ theme }) => theme.bluePurpleGradient};
      color: white;
    }
  }

  img {
    height: 1.5rem;
    margin-right: 6px;
  }

  @media screen and (max-width: 600px) {
    strong {
      display: flex;
    }
  }
`;
