import Main from "./styles";

import Button from "components/Button";
import EstablishmentFormSection from "components/EstablishmentFormSection";
import Input from "components/Input";
import FormPageTemplate from "components/LYT_FormPage";
import PatientFormSection, {
  patientSectionInitialValues,
} from "components/PatientFormSection";
import PremiumBlock from "components/PremiumBlock";
import ProfessionalFormSection from "components/ProfessionalFormSection";
import { useFormik } from "formik";
import React from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import usePatientParams from "services/hooks/usePatientParams";

const initialValues = {
    establishmentName: "USF Sede II - Água Fria",
    establishmentCNES: "5210143",
    professionalUf: "BA",
  },
  patientSectionRequiredFields = {
    name: true,
    motherName: true,
    dob: true,
    sex: true,
    cns: true,
    address: {},
  },
  patientSectionVisibleFields = {
    address: {
      cep: true,
      street: true,
      number: true,
      complement: true,
      neighborhood: true,
      city: true,
      uf: true,
      country: true,
    },
  };

export default () => {
  const formRef = useRef(null),
    formik = useFormik({
      initialValues: usePatientParams({
        ...patientSectionInitialValues,
        ...initialValues,
      }),
    });

  return (
    <FormPageTemplate
      metaTags={{
        title: "Documentos da Gestante",
        description:
          "Caderneta de Acompanhamento, prescrições e solicitação de exames para gestantes facilitada.",
        imagePath: "apac.jpg",
      }}
    >
      <Main>
        <header>
          <h1>
            <p>Gerar documentos da</p>Gestante{" "}
            <Link to="/">[Ver outros Documentos]</Link>
            <p>
              Gere os documentos médicos necessários para aocmpanhamento da
              gestante de baixo risco: exames, prescrições e caderneta da
              gestante. Dê a sua paciente acesso aos dados com gráficos e mais!
            </p>
          </h1>
          <p className="warning">
            Em breve teremos também a possibilidade de a gestante acompanhar sua
            gravidez por uma caderneta 100% online, atualizada em tempo real
          </p>
        </header>
        <form ref={formRef}>
          <PremiumBlock style={{ height: formRef?.current?.clientHeight }} />
          <section>
            <header>
              <h2>Dados do Solicitante</h2>
            </header>
            <ProfessionalFormSection formik={formik} />
          </section>
          <section>
            <header>
              <h2>Dados do Estabelecimento</h2>
            </header>
            <EstablishmentFormSection formik={formik} />
          </section>
          <section>
            <header>
              <h2>Dados da Paciente</h2>
            </header>
            <PatientFormSection
              formik={formik}
              requiredFields={patientSectionRequiredFields}
              visibleFields={patientSectionVisibleFields}
            />
          </section>
          <section>
            <header>
              <h2>Dados da Anamnese</h2>
            </header>
            <section>
              <Input type="date" placeholder="Data da Última Menstruação" />
              <Input type="date" placeholder="Data do Primeiro exame de USG" />
              <Input
                label="Idade Gestacional no 1º USG"
                placeholder="7s5d"
                description="ex.: 8s6d, 6s, 11s2d"
              />
            </section>
          </section>
          <section>
            <header>
              <h2>Protocolo de insulinização SUS</h2>
            </header>
            <select>
              <option>Bolus</option>
              <option>Basal Bólus</option>
            </select>
            <input type="range" min="0.3" max="0.5" value="0.3" step="0.1" />
          </section>
          {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}
          <Button type="submit" disabled>
            Enviar
          </Button>
        </form>
      </Main>
    </FormPageTemplate>
  );
};
